<div class="row top-bar py-4 mx-0 col-12">
    <div class="col-12 col-md-2 col-lg-2 text-center p-2">
        <img src="/assets/logoDeveloper.png">
    </div>

    <div class="col-12 col-md-8 col-lg-8 text-center p-2">
        <h1 *ngIf="href">{{href}}</h1>
    </div>

    <div class="col-12 col-md-2 col-lg-2 text-center p-2">
        <a href="https://www.clickatell.com/">Return to main site</a>
    </div>
</div>