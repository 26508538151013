import { Component, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticateService } from './../../services/authentication/authenticate.service';
import { RecaptchaErrorParameters } from "ng-recaptcha";
import { environment } from 'src/environments/environment';
import {HttpClient} from '@angular/common/http';
import { map } from 'rxjs/operators';
import {CookieService} from "ngx-cookie-service";
declare var unserialize:any;
declare var serialize:any;
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  private comm2Endpoint = environment.comm2ApiUrl;
  private centralEndpoint = environment.centralApiUrl;
  private contentapi = environment.contentApi;
  public error_message_response: string = '';
  public error_message: boolean = false;
  public loading: boolean = false;
  public reCAPTCHA: boolean = false;
  public newClientID: string = '';
  public captchaResponse: string = '';
  public captchaError: any;
  public responseMessage: any = {Status:"",Error:""};
  public remainingAttempts: number = 4;

  public recentToken: string = "";
  public clientIDRequired: boolean = false;
  public applicationLogin: string = "";
  public recentError?: { error: any };
  public ipAddress: any;
  private preAuthXsrfToken: string = '';

  public dublicateUserCheck = {Status: " ", Error: ""};


  public loginForm = new FormGroup({
    username: new FormControl('',[
      Validators.required,
      Validators.minLength(2)
    ]),
    password: new FormControl('',[
      Validators.required,
      Validators.minLength(6)
    ]),
    accountType: new FormControl('',[

    ]),
    clientid: new FormControl('',[
      Validators.minLength(2)
    ]),
    recaptcha: new FormControl (''),
    preAuthXsrfToken: new FormControl('')
  })

  constructor(
    private activatedRoute: ActivatedRoute,
    private userLogin: AuthenticateService,
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.activatedRoute.queryParams.subscribe(params => {
      if(Object.keys(params).length){
        let method = params['method'];
        let username = params['username'];
        let password = params['password'];
        let clientid = params['client_id'];

        var newObject:any = [];
        newObject['username'] = btoa(username);
        newObject['password'] = btoa(password);
        newObject['usertoken'] = '';
        newObject['clientid'] = clientid;
        newObject['ip'] = '';
        newObject['IpAddress'] = '';
        newObject['paramLogin'] = true;
        newObject['duplicateCheck'] = false;

        let preAuthXsrfToken = this.loginForm.controls['preAuthXsrfToken'].value;
        this.userLogin.preAuth(this.contentapi, preAuthXsrfToken)
          .subscribe((response) => {
            const data = response.body;
            if (response.headers.has('_preAuthXsrfToken')) {
              this.preAuthXsrfToken = response.headers.get('_preAuthXsrfToken');
            } else {
              this.preAuthXsrfToken = data.PreAuthCsrf;
            }
            newObject['preAuthXsrfToken'] = this.preAuthXsrfToken;

            let filter = serialize(newObject);
            let newFilter = btoa(filter);

            this.userLogin
              .loginUser(
                'loginNew',
                'serialize',
                newFilter,
                this.contentapi
              ).pipe(
                map(response => (
                  {
                    authid: unserialize(response.body)
                  }
                ))
              ).subscribe(data => console.log(data));
          });
      }
    });
   }

  ngOnInit(): void {}

  ngAfterViewInit(): void{
     const ms = 180000;
     this.HandlePreAuth();
     setInterval(()=> {
       this.HandlePreAuth();
     }, ms);
  }

  public resolved(captchaResponse: string): void {
    //console.log(`Resolved captcha with response: ${captchaResponse}`);
    this.captchaResponse = captchaResponse;
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    //console.log(`reCAPTCHA error encountered; details:`, errorDetails);
    this.captchaError = errorDetails;
  }


  public checkDuplicateAccount(event: any): void{

    let username = event.target.value;

      if(username === '' || username === null){
        this.loading = false;
        this.error_message = true;
        this.error_message_response = 'Please enter your username.';
      }
      else{

        var newObject:any = new Array();
        newObject['username'] = btoa(username);
        newObject['paramLogin'] = false;
        newObject['duplicateCheck'] = true;
        newObject['preAuthXsrfToken'] = this.preAuthXsrfToken;
        let content = 'validateusername';
        let type = 'serialize';
        let filter = serialize(newObject);
        let newFilter = btoa(filter);

        this.error_message = false;
        this.userLogin.checkDuplicateUserAccounts(content, type, newFilter, this.contentapi).subscribe((response: any) => {
          this.dublicateUserCheck = unserialize(response);
          this.responseMessage = unserialize(response);
          this.reCAPTCHA = true;
        },(error) => {
          console.log('error');
          console.log(error);
        });
      }
  }

  public signIn(){
    this.loading = false;
    this.error_message = false;

    this.loading = true;
    let username = this.loginForm.controls['username'].value;
    let password = this.loginForm.controls['password'].value;
    let clientid = this.loginForm.controls['clientid'].value;
    let preAuthXsrfToken = this.loginForm.controls['preAuthXsrfToken'].value;

    if(username == '' || password == ''){
      this.error_message_response = 'Please fill in all your fields';
    }
    else if(this.loginForm.controls['recaptcha'].pristine == true && this.loginForm.controls['recaptcha'].status === "VALID"){

          if(this.applicationLogin === 'developer-central' && clientid === '' && this.dublicateUserCheck!.Error === 'Duplicate username - ClientID required.'){
            this.error_message_response = 'Please enter Client ID';
            this.loading = false;
            this.error_message = true;
          }
          else if(this.dublicateUserCheck!.Error === 'Duplicate username - ClientID required.' && this.applicationLogin == ''){
            this.error_message_response = 'Please choose an account type';
            this.loading = false;
            this.error_message = true;
          }
          else{

            // Setting the Client ID
            if(this.applicationLogin === 'communicator2'){
              this.newClientID = "COMMUN";
            }
            else{
              this.newClientID = clientid;
            }
              var newObject:any = new Array();
              newObject['paramLogin'] = false;
              newObject['username'] = btoa(username);
              newObject['password'] = btoa(password);
              newObject['usertoken'] = '';
              newObject['clientid'] = this.newClientID;
              newObject['preAuthXsrfToken'] = preAuthXsrfToken;
              newObject['duplicateCheck'] = false;
              newObject['ip'] = '';
              newObject['IpAddress'] = '';

              let content = 'loginNew';
              let type = 'serialize';
              let filter = serialize(newObject);
              let newFilter = btoa(filter);

              if((this.reCAPTCHA === true) && (this.captchaResponse == '') && (this.responseMessage.Error === 'CAPTCHA')){
                this.error_message_response = "Invalid Captcha."
                  this.loading = false;
                  this.error_message = true;
              }
              else{
                this.userLogin.loginUser(content, type, newFilter, this.contentapi)
                  .subscribe((response: any) => {
                    if (response.headers.has('_XSRF-TOKEN')) {
                      const date = new Date();
                      date.setHours(date.getHours() + 24);
                      this.cookieService.delete('_XSRF-TOKEN');
                      this.cookieService.set(
                        'XSRF-TOKEN',
                        response.headers.get('_XSRF-TOKEN'),
                        date,
                        '/',
                        '.clickatell.com',
                        true,
                        'None'
                      );
                    }
                  this.responseMessage = response.body;
                  let responseData = unserialize(response.body);
                  if(responseData.Status === "OK" && responseData.ClientId === "COMMUN"){
                      let newResponse = responseData.Params;
                      window.location.href = this.comm2Endpoint+'?render=Home&'+newResponse;
                  } else if(responseData.Status === "OK" && responseData.ClientId !== "COMMUN"){
                      let newResponse = responseData.Params;
                      // redirect
                      window.location.href = this.centralEndpoint+'?'+newResponse
                  } else if(responseData.Status === "Failed" && responseData.Error === 'CAPTCHA'){
                      this.reCAPTCHA = true;
                      this.error_message_response = responseData.Status+': Login Failed';
                      this.loading = false;
                      this.error_message = true;
                  } else if(responseData.Status === "Failed" && responseData.Error === "Login Failed"){
                      this.error_message_response = responseData.Status+': '+responseData.Error;
                      this.loading = false;
                      this.error_message = true;

                  } else if(responseData.Status === "Failed" && responseData.Error === "Unauthorized Access"){
                      this.error_message_response = responseData.Status+': Login Failed';
                      this.loading = false;
                      this.error_message = true;
                  }

                  if (responseData.Status === "Failed") {
                      this.HandlePreAuth();
                  }

                },(error) => {
                  console.log(error);
                  this.HandlePreAuth();
                });
              }
          }
    }
    else if(this.loginForm.controls['recaptcha'].pristine !== true && this.loginForm.controls['recaptcha'].status !== "VALID"){
      this.loading = false;
      this.error_message_response = "Invalid Captcha entered. Please try again.";
      this.error_message = true;
    }

  }

  public eventCheck(event: any){
    let eventCheck = event.target.checked;
    let eventValue = event.target.value
    this.applicationLogin = eventValue;
    if(eventCheck === true && eventValue === "developer-central"){
      this.clientIDRequired = eventCheck;
    }
    else{
      this.clientIDRequired = false;
    }
  }

  private HandlePreAuth(): void {
    this.userLogin.preAuth(
      this.contentapi,
      this.loginForm.controls['preAuthXsrfToken'].value
    ).subscribe(res => {
      const data = unserialize(res.body);
      if (res.headers.has('_preAuthXsrfToken')) {
        this.loginForm.controls['preAuthXsrfToken']
          .setValue(res.headers.get('_preAuthXsrfToken'));
      } else {
        this.loginForm.controls['preAuthXsrfToken']
          .setValue(data.preAuthCsrf);
      }
    });
  }
}
