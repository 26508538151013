// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  reCaptchaVerify: 'https://www.google.com/recaptcha/api/siteverify',
  comm2ApiUrl: "https://communicator2.clickatell.com/index.php",
  centralApiUrl: "https://central.clickatell.com/index/login",
  contentApi: "https://api.clickatell.com/content/get.php",
  secretKey: '6LfhM9oaAAAAANfpMOcR0JoHkvkrDQH0afdxl4hZ',
  token: '2A133E783FBEE221F27D5A11F0CD250F146E473F735BC9B8F149A1E523CD5D43'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
