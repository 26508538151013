import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthenticateService } from './../../services/authentication/authenticate.service';
declare var unserialize:any;
declare var serialize:any;

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  private contentapi = environment.contentApi;
  public newClientID: string = '';
  public error_message_response: any;
  public error_message: boolean = false;
  public success_message: boolean = false;
  public loading: boolean = false;
  public applicationLogin: string = "";
  public clientIDRequired: boolean = false;
  public responseMessage: string = "";
  public dublicateUserCheck = {Status: " ", Error: ""};

  public recoverForm = new FormGroup({
    username: new FormControl('',[
      Validators.required,
    ]),
    accountType: new FormControl(''),
    userEmail: new FormControl('',[
      Validators.required,
      Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,20}')
    ]),
    clientid: new FormControl('',[
      Validators.minLength(2)
    ]),
  })

  constructor(private userLogin: AuthenticateService) { }

  ngOnInit(): void {
  }


  public checkDuplicateAccount(event: any): void{

    let username = event.target.value;

      if(username === '' || username === null){
        this.loading = false;
        this.error_message = true;
        this.error_message_response = 'Please enter your username.';
      }
      else{
        var newObject:any = new Array();
        newObject['username'] = btoa(username);
        newObject['paramLogin'] = false;
        newObject['duplicateCheck'] = true;

        let content = 'duplicateCheck';
        let type = 'serialize';
        let filter = serialize(newObject);
        let newFilter = btoa(filter);

        this.error_message = false;
        this.userLogin.checkDuplicateUserAccounts(content, type, newFilter, this.contentapi).subscribe((response: any) => {
          this.dublicateUserCheck = unserialize(response);
        },(error) => {
          console.log('error');
          console.log(error);
        });

      }
  }

  public passwordReset(){

    this.success_message = false;
    this.error_message = false;
    this.loading = true;

    let username = this.recoverForm.controls['username'].value
    let userEmail = this.recoverForm.controls['userEmail'].value
    let clientid = this.recoverForm.controls['clientid'].value;

    // Setting the Client ID
    if(this.applicationLogin === 'communicator2'){
      this.newClientID = "COMMUN";
    }
    else{
      this.newClientID = clientid;
    }

    var newObject:any = new Array();
    newObject['username'] = btoa(username);
    newObject['email'] = btoa(userEmail);
    newObject['clientid'] = this.newClientID;

    let content = 'forgotpassword';
    let type = 'serialize';
    let filter = serialize(newObject);
    let newFilter = btoa(filter);

    this.userLogin.forgotPassword(content, type, newFilter, this.contentapi).subscribe((response: any) => {
      let newResponse = unserialize(response);
      this.error_message_response = newResponse.substring(6);

      this.responseMessage = newResponse;

      if(unserialize(response) == 'OK'){
        this.loading = false;
        this.success_message = true;
        this.error_message = false;
      }
      else{
        this.loading = false;
        this.success_message = false;
        this.error_message = true;
      }
    },(error) => {
      console.log(error);
    });

  }





  public eventCheck(event: any){

    let eventCheck = event.target.checked;
    let eventValue = event.target.value
    this.applicationLogin = eventValue;

    if(eventCheck === true && eventValue === "developer-central"){
      this.clientIDRequired = eventCheck;
    }
    else{
      this.clientIDRequired = false;
    }
  }

}
