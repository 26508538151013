import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { CountrySelected, ResponseMessage } from 'src/app/interfaces/countrySelected';
import { Register } from 'src/app/interfaces/registerUser';
import { UsernameSuggest } from 'src/app/interfaces/usernameSuggest';
import { AuthenticateService } from 'src/app/services/authentication/authenticate.service';
import { environment } from 'src/environments/environment';
declare var unserialize:any;
declare var serialize:any;
declare var $: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  private contentapi = environment.contentApi;
  public firstSection: boolean = true;
  public secondSection: boolean = false;
  public thirdSection: boolean = false;
  public countries: any;
  public displayCountries: boolean = true;
  public selectedCountryOptions : any = [];
  public countryObject!: CountrySelected;
  public hideMultiSelect: boolean = false;
  public errormessage: string = '';
  public responseData: any = '';
  public buttonHide: boolean = false;
  public accountType: string = '';
  public applicationType: string = '';
  public billingProfile: any;
  public newBillingProfile: any;
  public toggleResponseMessage: boolean = false;
  public checkUsername: UsernameSuggest = {};

  public separateDialCode = false;
	public SearchCountryField = SearchCountryField;
	public CountryISO = CountryISO;
  public PhoneNumberFormat = PhoneNumberFormat;
	public preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates, CountryISO.UnitedKingdom,
    CountryISO.SouthAfrica
  ];
  public phoneNumberResponse: any;
  public formatedPhoneNumber: any;

  public alphaRegex: boolean = false;
  public numericRegex: boolean = false;
  public validatePass: boolean = false;
  public whitespaceValidate: boolean = false;
  public ipAddress: any;



  public registerForm = new FormGroup({
    firstName: new FormControl('',[
      Validators.required,
      Validators.minLength(3),
      Validators.pattern('^[a-zA-Z \-\']+')
    ]),
    lastName: new FormControl('',[
      Validators.required,
      Validators.minLength(3),
      Validators.pattern('^[a-zA-Z \-\']+')
    ]),
    company: new FormControl('',[
      Validators.required,
      Validators.minLength(2)
    ]),
    username: new FormControl('',[
      Validators.required,
      Validators.minLength(3)
    ]),
    cellPhone: new FormControl('',[
      Validators.required,
      Validators.pattern('[0-9]*')
    ]),
    emailAddress: new FormControl('',[
      Validators.required,
      Validators.pattern("[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,10}")
    ]),
    anywhereInput: new FormControl('',[
      Validators.required,
    ]),
  })

  constructor(private userLogin: AuthenticateService, private router: Router, private http: HttpClient) {

  }

  ngOnInit(): void {

    this.countries = [
      {
        "countryID": 1,
        "name": "Afghanistan",
        "domain": "AF",
        "phone_code": 93,
        "mcc": "412",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Kabul",
        "longitude": 69.11,
        "latitude": 34.28,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 2,
        "name": "Albania",
        "domain": "AL",
        "phone_code": 355,
        "mcc": "276",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Tirane",
        "longitude": 19.49,
        "latitude": 41.18,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 3,
        "name": "Algeria",
        "domain": "DZ",
        "phone_code": 213,
        "mcc": "603",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Algiers",
        "longitude": 3.08,
        "latitude": 36.42,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 4,
        "name": "Saint Barthelemy",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 5,
        "name": "Andorra",
        "domain": "AD",
        "phone_code": 376,
        "mcc": "213",
        "min_mobile_num": 9,
        "max_mobile_num": 9,
        "capital": "Andorra la Vella",
        "longitude": 1.32,
        "latitude": 42.31,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 6,
        "name": "Anguilla",
        "domain": "AI",
        "phone_code": 1264,
        "mcc": "365",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 8,
        "name": "Antarctica",
        "domain": "AQ",
        "phone_code": 672,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 11,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 9,
        "name": "Antigua & Barbuda",
        "domain": "AG",
        "phone_code": 1268,
        "mcc": "344",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "W. Indies",
        "longitude": -61.48,
        "latitude": 17.2,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 10,
        "name": "Argentina",
        "domain": "AR",
        "phone_code": 54,
        "mcc": "722",
        "min_mobile_num": 12,
        "max_mobile_num": 13,
        "capital": "Buenos Aires",
        "longitude": -60,
        "latitude": -36.3,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 11,
        "name": "Armenia",
        "domain": "AM",
        "phone_code": 374,
        "mcc": "283",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Yerevan",
        "longitude": 44.31,
        "latitude": 40.1,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 13,
        "name": "Ascension Island",
        "domain": "AC",
        "phone_code": 247,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 14,
        "name": "Australia",
        "domain": "AU",
        "phone_code": 61,
        "mcc": "505",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Canberra",
        "longitude": 149.08,
        "latitude": -35.15,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 16,
        "name": "Austria",
        "domain": "AT",
        "phone_code": 43,
        "mcc": "232,690",
        "min_mobile_num": 12,
        "max_mobile_num": 14,
        "capital": "Vienna",
        "longitude": 16.22,
        "latitude": 48.12,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 18,
        "name": "Azerbaijan",
        "domain": "AZ",
        "phone_code": 994,
        "mcc": "400",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Baku",
        "longitude": 49.56,
        "latitude": 40.29,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 19,
        "name": "Bahamas, the",
        "domain": "BS",
        "phone_code": 1242,
        "mcc": "364",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Nassau",
        "longitude": -77.2,
        "latitude": 25.05,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 20,
        "name": "Bahrain",
        "domain": "BH",
        "phone_code": 973,
        "mcc": "426",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Manama",
        "longitude": 50.3,
        "latitude": 26.1,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 21,
        "name": "Bangladesh",
        "domain": "BD",
        "phone_code": 880,
        "mcc": "470",
        "min_mobile_num": 12,
        "max_mobile_num": 14,
        "capital": "Dhaka",
        "longitude": 90.26,
        "latitude": 23.43,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 22,
        "name": "Barbados",
        "domain": "BB",
        "phone_code": 1246,
        "mcc": "342",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Bridgetown",
        "longitude": -59.3,
        "latitude": 13.05,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 23,
        "name": "Barbuda (duplicate of Antigua and Barbuda)",
        "domain": "AG",
        "phone_code": 1268,
        "mcc": "344",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 24,
        "name": "Belarus",
        "domain": "BY",
        "phone_code": 375,
        "mcc": "257",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Minsk",
        "longitude": 27.3,
        "latitude": 53.52,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 25,
        "name": "Belgium",
        "domain": "BE",
        "phone_code": 32,
        "mcc": "206",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Brussels",
        "longitude": 4.21,
        "latitude": 50.51,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 27,
        "name": "Belize",
        "domain": "BZ",
        "phone_code": 501,
        "mcc": "702",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Belmopan",
        "longitude": -88.3,
        "latitude": 17.18,
        "region_id": 5,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 28,
        "name": "Benin",
        "domain": "BJ",
        "phone_code": 229,
        "mcc": "616",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Porto-Novo",
        "longitude": 2.42,
        "latitude": 6.23,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 29,
        "name": "Bermuda",
        "domain": "BM",
        "phone_code": 1441,
        "mcc": "350",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 9,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 30,
        "name": "Bhutan",
        "domain": "BT",
        "phone_code": 975,
        "mcc": "402",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Thimphu",
        "longitude": 89.45,
        "latitude": 27.31,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 31,
        "name": "Bolivia",
        "domain": "BO",
        "phone_code": 591,
        "mcc": "736",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "La Paz",
        "longitude": -68.1,
        "latitude": -16.2,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 32,
        "name": "Bosnia-Herzegovina",
        "domain": "BA",
        "phone_code": 387,
        "mcc": "218",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Sarajevo",
        "longitude": 18.26,
        "latitude": 43.52,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 33,
        "name": "Botswana",
        "domain": "BW",
        "phone_code": 267,
        "mcc": "652",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Gaborone",
        "longitude": 25.57,
        "latitude": -24.45,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 34,
        "name": "Bouvet Island",
        "domain": "BV",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 11,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 35,
        "name": "Brazil",
        "domain": "BR",
        "phone_code": 55,
        "mcc": "724",
        "min_mobile_num": 11,
        "max_mobile_num": 14,
        "capital": "Brasilia",
        "longitude": -47.55,
        "latitude": -15.47,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 36,
        "name": "British Indian Ocean",
        "domain": "IO",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 37,
        "name": "British Virgin Islands",
        "domain": "VG",
        "phone_code": 1284,
        "mcc": "348",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Road Town",
        "longitude": -64.37,
        "latitude": 18.27,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 38,
        "name": "Brunei",
        "domain": "BN",
        "phone_code": 673,
        "mcc": "528",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Bandar Seri Begawan",
        "longitude": 115,
        "latitude": 4.52,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 39,
        "name": "Bulgaria",
        "domain": "BG",
        "phone_code": 359,
        "mcc": "284",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Sofia",
        "longitude": 23.2,
        "latitude": 42.45,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 40,
        "name": "Burkina Faso",
        "domain": "BF",
        "phone_code": 226,
        "mcc": "613",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Ouagadougou",
        "longitude": -1.3,
        "latitude": 12.15,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 41,
        "name": "Burundi",
        "domain": "BI",
        "phone_code": 257,
        "mcc": "642",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Bujumbura",
        "longitude": 29.18,
        "latitude": -3.16,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 42,
        "name": "Cambodia",
        "domain": "KH",
        "phone_code": 855,
        "mcc": "456",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Phnom Penh",
        "longitude": 104.55,
        "latitude": 11.33,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 44,
        "name": "Cameroon",
        "domain": "CM",
        "phone_code": 237,
        "mcc": "624",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Yaounde",
        "longitude": 11.35,
        "latitude": 3.5,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 45,
        "name": "Canada",
        "domain": "CA",
        "phone_code": 1,
        "mcc": "302,311,316",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Ottawa",
        "longitude": -75.42,
        "latitude": 45.27,
        "region_id": 9,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 46,
        "name": "Cape Verde",
        "domain": "CV",
        "phone_code": 238,
        "mcc": "625",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Praia",
        "longitude": -23.34,
        "latitude": 15.02,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 47,
        "name": "Cayman Islands",
        "domain": "KY",
        "phone_code": 1345,
        "mcc": "346",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "George Town",
        "longitude": -81.24,
        "latitude": 19.2,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 48,
        "name": "Central African Republic",
        "domain": "CF",
        "phone_code": 236,
        "mcc": "623",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Bangui",
        "longitude": 18.35,
        "latitude": 4.23,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 49,
        "name": "Chad",
        "domain": "TD",
        "phone_code": 235,
        "mcc": "622",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "N'Djamena",
        "longitude": 14.59,
        "latitude": 12.1,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 50,
        "name": "Chatham Island",
        "domain": "",
        "phone_code": 64,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 51,
        "name": "Chile",
        "domain": "CL",
        "phone_code": 56,
        "mcc": "730",
        "min_mobile_num": 10,
        "max_mobile_num": 12,
        "capital": "Santiago",
        "longitude": -70.4,
        "latitude": -33.24,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 52,
        "name": "China",
        "domain": "CN",
        "phone_code": 86,
        "mcc": "460",
        "min_mobile_num": 13,
        "max_mobile_num": 13,
        "capital": "Beijing",
        "longitude": 116.2,
        "latitude": 39.55,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 53,
        "name": "Christmas Island",
        "domain": "CX",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 54,
        "name": "Cocos (Keeling) Island",
        "domain": "CC",
        "phone_code": 891,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "West Island",
        "longitude": 0,
        "latitude": 0,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 55,
        "name": "Colombia",
        "domain": "CO",
        "phone_code": 57,
        "mcc": "732",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Bogota",
        "longitude": -74,
        "latitude": 4.34,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 56,
        "name": "Comoros",
        "domain": "KM",
        "phone_code": 269,
        "mcc": "654",
        "min_mobile_num": 10,
        "max_mobile_num": 13,
        "capital": "Moroni",
        "longitude": 43.16,
        "latitude": -11.4,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 57,
        "name": "Congo",
        "domain": "CG",
        "phone_code": 242,
        "mcc": "629",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Brazzaville",
        "longitude": 15.12,
        "latitude": -4.09,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 58,
        "name": "Congo, Democratic Republic",
        "domain": "CD",
        "phone_code": 243,
        "mcc": "630",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Kinshasa",
        "longitude": 15.15,
        "latitude": -4.2,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 59,
        "name": "Cook Islands",
        "domain": "CK",
        "phone_code": 682,
        "mcc": "548",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 60,
        "name": "Costa Rica",
        "domain": "CR",
        "phone_code": 506,
        "mcc": "712",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "San Jose",
        "longitude": -84.02,
        "latitude": 9.55,
        "region_id": 5,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 61,
        "name": "Croatia",
        "domain": "HR",
        "phone_code": 385,
        "mcc": "219",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Zagreb",
        "longitude": 15.58,
        "latitude": 45.5,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 62,
        "name": "Cuba",
        "domain": "CU",
        "phone_code": 53,
        "mcc": "368",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Havana",
        "longitude": -82.22,
        "latitude": 23.08,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 64,
        "name": "Curacao",
        "domain": "",
        "phone_code": 599,
        "mcc": "362",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 65,
        "name": "Cyprus",
        "domain": "CY",
        "phone_code": 357,
        "mcc": "280",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Nicosia",
        "longitude": 33.25,
        "latitude": 35.1,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 66,
        "name": "Czech Republic",
        "domain": "CZ",
        "phone_code": 420,
        "mcc": "230",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Prague",
        "longitude": 14.22,
        "latitude": 50.05,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 67,
        "name": "Denmark",
        "domain": "DK",
        "phone_code": 45,
        "mcc": "238",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Copenhagen",
        "longitude": 12.34,
        "latitude": 55.41,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 68,
        "name": "Atlantis (Test System)",
        "domain": "",
        "phone_code": 279,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 70,
        "name": "Diego Garcia",
        "domain": "fake",
        "phone_code": 246,
        "mcc": "995,997",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 71,
        "name": "Djibouti",
        "domain": "DJ",
        "phone_code": 253,
        "mcc": "638",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Djibouti",
        "longitude": 42.2,
        "latitude": 11.08,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 72,
        "name": "Iran-Kish Island",
        "domain": "",
        "phone_code": 98,
        "mcc": "432",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 73,
        "name": "Dominican Republic",
        "domain": "DO",
        "phone_code": 1,
        "mcc": "370",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Santo Domingo",
        "longitude": -69.59,
        "latitude": 18.3,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 74,
        "name": "East Timor (Timor-Leste)",
        "domain": "TL",
        "phone_code": 670,
        "mcc": "514",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Dili",
        "longitude": 125.34,
        "latitude": -8.29,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 75,
        "name": "Easter Island",
        "domain": "",
        "phone_code": 56,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 76,
        "name": "Ecuador",
        "domain": "EC",
        "phone_code": 593,
        "mcc": "740",
        "min_mobile_num": 11,
        "max_mobile_num": 13,
        "capital": "Quito",
        "longitude": -78.35,
        "latitude": -0.15,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 77,
        "name": "Egypt",
        "domain": "EG",
        "phone_code": 20,
        "mcc": "602",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Cairo",
        "longitude": 31.14,
        "latitude": 30.01,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 78,
        "name": "El Salvador",
        "domain": "SV",
        "phone_code": 503,
        "mcc": "706",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "San Salvador",
        "longitude": -89.1,
        "latitude": 13.4,
        "region_id": 5,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 79,
        "name": "Equatorial Guinea",
        "domain": "GQ",
        "phone_code": 240,
        "mcc": "627",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Malabo",
        "longitude": 8.5,
        "latitude": 3.45,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 80,
        "name": "Eritrea",
        "domain": "ER",
        "phone_code": 291,
        "mcc": "657",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Asmara",
        "longitude": 38.55,
        "latitude": 15.19,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 81,
        "name": "Estonia",
        "domain": "EE",
        "phone_code": 372,
        "mcc": "248",
        "min_mobile_num": 10,
        "max_mobile_num": 11,
        "capital": "Tallinn",
        "longitude": 24.48,
        "latitude": 59.22,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 82,
        "name": "Falkland Islands",
        "domain": "FK",
        "phone_code": 500,
        "mcc": "750",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Stanley",
        "longitude": -59.51,
        "latitude": -51.4,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 83,
        "name": "Faroe Islands",
        "domain": "FO",
        "phone_code": 298,
        "mcc": "288",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Torshavn",
        "longitude": -6.56,
        "latitude": 62.05,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 84,
        "name": "Fiji",
        "domain": "FJ",
        "phone_code": 679,
        "mcc": "542",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Suva",
        "longitude": 178.3,
        "latitude": -18.06,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 85,
        "name": "Finland",
        "domain": "FI",
        "phone_code": 358,
        "mcc": "244",
        "min_mobile_num": 9,
        "max_mobile_num": 13,
        "capital": "Helsinki",
        "longitude": 25.03,
        "latitude": 60.15,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 86,
        "name": "France",
        "domain": "FR",
        "phone_code": 33,
        "mcc": "208",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Paris",
        "longitude": 2.2,
        "latitude": 48.5,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 88,
        "name": "zN/A 2",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 89,
        "name": "French Guiana",
        "domain": "GF",
        "phone_code": 594,
        "mcc": "340",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Cayenne",
        "longitude": -52.18,
        "latitude": 5.05,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 90,
        "name": "French Polynesia",
        "domain": "PF",
        "phone_code": 689,
        "mcc": "547",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Papeete",
        "longitude": -149.34,
        "latitude": -17.32,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 91,
        "name": "French Southern Territory",
        "domain": "TF",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 11,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 92,
        "name": "French West Indies/Antilles",
        "domain": "",
        "phone_code": 590,
        "mcc": "340",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 93,
        "name": "Gabon",
        "domain": "GA",
        "phone_code": 241,
        "mcc": "628",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Libreville",
        "longitude": 9.26,
        "latitude": 0.25,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 94,
        "name": "Gambia",
        "domain": "GM",
        "phone_code": 220,
        "mcc": "607",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Banjul",
        "longitude": -16.4,
        "latitude": 13.28,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 95,
        "name": "Georgia",
        "domain": "GE",
        "phone_code": 995,
        "mcc": "282",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "T'bilisi",
        "longitude": 44.5,
        "latitude": 41.43,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 96,
        "name": "Germany",
        "domain": "DE",
        "phone_code": 49,
        "mcc": "262",
        "min_mobile_num": 10,
        "max_mobile_num": 14,
        "capital": "Berlin",
        "longitude": 13.25,
        "latitude": 52.3,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 98,
        "name": "Ghana",
        "domain": "GH",
        "phone_code": 233,
        "mcc": "620",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Accra",
        "longitude": -0.06,
        "latitude": 5.35,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 99,
        "name": "Gibraltar",
        "domain": "GI",
        "phone_code": 350,
        "mcc": "266",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 100,
        "name": "Greece",
        "domain": "GR",
        "phone_code": 30,
        "mcc": "202",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Athens",
        "longitude": 23.46,
        "latitude": 37.58,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 102,
        "name": "Greenland",
        "domain": "GL",
        "phone_code": 299,
        "mcc": "290",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Nuuk",
        "longitude": -51.35,
        "latitude": 64.1,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 103,
        "name": "Grenada",
        "domain": "GD",
        "phone_code": 1473,
        "mcc": "352",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 104,
        "name": "Guadeloupe",
        "domain": "GP",
        "phone_code": 590,
        "mcc": "340",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Basse-Terre",
        "longitude": -61.44,
        "latitude": 16,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 105,
        "name": "Guam",
        "domain": "GU",
        "phone_code": 1671,
        "mcc": "310",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 106,
        "name": "Guantanamo Bay",
        "domain": "",
        "phone_code": 53,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 107,
        "name": "Guatemala",
        "domain": "GT",
        "phone_code": 502,
        "mcc": "704",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Guatemala",
        "longitude": -90.22,
        "latitude": 14.4,
        "region_id": 5,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 108,
        "name": "UK",
        "domain": "GB",
        "phone_code": 44,
        "mcc": "234,235",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "London",
        "longitude": -0.05,
        "latitude": 51.36,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 109,
        "name": "Guinea",
        "domain": "GN",
        "phone_code": 224,
        "mcc": "611",
        "min_mobile_num": 10,
        "max_mobile_num": 13,
        "capital": "Conakry",
        "longitude": -13.49,
        "latitude": 9.29,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 110,
        "name": "Guinea-Bissau",
        "domain": "GW",
        "phone_code": 245,
        "mcc": "632",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Bissau",
        "longitude": -15.45,
        "latitude": 11.45,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 111,
        "name": "Guyana",
        "domain": "GY",
        "phone_code": 592,
        "mcc": "738",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Georgetown",
        "longitude": -58.12,
        "latitude": 6.5,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 112,
        "name": "Haiti",
        "domain": "HT",
        "phone_code": 509,
        "mcc": "372",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Port-au-Prince",
        "longitude": -72.2,
        "latitude": 18.4,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 113,
        "name": "Honduras",
        "domain": "HN",
        "phone_code": 504,
        "mcc": "708",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Tegucigalpa",
        "longitude": -87.14,
        "latitude": 14.05,
        "region_id": 5,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 114,
        "name": "Hong Kong",
        "domain": "HK",
        "phone_code": 852,
        "mcc": "454",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 118,
        "name": "Hungary",
        "domain": "HU",
        "phone_code": 36,
        "mcc": "216",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Budapest",
        "longitude": 19.05,
        "latitude": 47.29,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 120,
        "name": "Iceland",
        "domain": "IS",
        "phone_code": 354,
        "mcc": "274",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Reykjavik",
        "longitude": -21.57,
        "latitude": 64.1,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 121,
        "name": "India",
        "domain": "IN",
        "phone_code": 91,
        "mcc": "405,404",
        "min_mobile_num": 10,
        "max_mobile_num": 13,
        "capital": "New Delhi",
        "longitude": 77.13,
        "latitude": 28.37,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 132,
        "name": "Indonesia",
        "domain": "ID",
        "phone_code": 62,
        "mcc": "510",
        "min_mobile_num": 11,
        "max_mobile_num": 14,
        "capital": "Jakarta",
        "longitude": 106.49,
        "latitude": -6.09,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 134,
        "name": "Inmarsat",
        "domain": "",
        "phone_code": 870,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 135,
        "name": "Inmarsat (Atlantic Ocean)",
        "domain": "",
        "phone_code": 874,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 136,
        "name": "Inmarsat (Indian Ocean)",
        "domain": "",
        "phone_code": 873,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 137,
        "name": "Inmarsat (Pacific Ocean)",
        "domain": "",
        "phone_code": 872,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 138,
        "name": "Inmarsat (SNAC)",
        "domain": "",
        "phone_code": 870,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 139,
        "name": "Iran",
        "domain": "IR",
        "phone_code": 98,
        "mcc": "432",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Tehran",
        "longitude": 51.3,
        "latitude": 35.44,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 140,
        "name": "Iraq",
        "domain": "IQ",
        "phone_code": 964,
        "mcc": "418",
        "min_mobile_num": 13,
        "max_mobile_num": 13,
        "capital": "Baghdad",
        "longitude": 44.3,
        "latitude": 33.2,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 141,
        "name": "Ireland",
        "domain": "IE",
        "phone_code": 353,
        "mcc": "272",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Dublin",
        "longitude": -6.15,
        "latitude": 53.21,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 142,
        "name": "Isle of Man",
        "domain": "IM",
        "phone_code": 44,
        "mcc": "234",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 143,
        "name": "Israel",
        "domain": "IL",
        "phone_code": 972,
        "mcc": "425",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Jerusalem",
        "longitude": -35.1,
        "latitude": 31.71,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 144,
        "name": "Italy",
        "domain": "IT",
        "phone_code": 39,
        "mcc": "222",
        "min_mobile_num": 10,
        "max_mobile_num": 13,
        "capital": "Rome",
        "longitude": 12.29,
        "latitude": 41.54,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 146,
        "name": "Ivory Coast (Cote d'Ivoire)",
        "domain": "CI",
        "phone_code": 225,
        "mcc": "612",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Yamoussoukro",
        "longitude": -5.17,
        "latitude": 6.49,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 147,
        "name": "Jamaica",
        "domain": "JM",
        "phone_code": 1876,
        "mcc": "338",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Kingston",
        "longitude": -76.5,
        "latitude": 18,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 148,
        "name": "Japan",
        "domain": "JP",
        "phone_code": 81,
        "mcc": "440,441",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Tokyo",
        "longitude": 139.46,
        "latitude": 35.41,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 149,
        "name": "Channel Islands",
        "domain": "",
        "phone_code": 44,
        "mcc": "234",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 150,
        "name": "Jordan",
        "domain": "JO",
        "phone_code": 962,
        "mcc": "416",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Amman",
        "longitude": 35.52,
        "latitude": 31.57,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 151,
        "name": "Kazakhstan",
        "domain": "KZ",
        "phone_code": 7,
        "mcc": "401",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Astana",
        "longitude": 71.3,
        "latitude": 51.1,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 153,
        "name": "Kenya",
        "domain": "KE",
        "phone_code": 254,
        "mcc": "639",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Nairobi",
        "longitude": 36.48,
        "latitude": -1.17,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 154,
        "name": "Kiribati",
        "domain": "KI",
        "phone_code": 686,
        "mcc": "545",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Tarawa",
        "longitude": 173,
        "latitude": 1.3,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 155,
        "name": "Korea (South)",
        "domain": "KR",
        "phone_code": 82,
        "mcc": "450",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Seoul",
        "longitude": 126.58,
        "latitude": 37.31,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 156,
        "name": "Korea (North)",
        "domain": "KP",
        "phone_code": 850,
        "mcc": "467",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "P'yongyang",
        "longitude": 125.3,
        "latitude": 39.09,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 157,
        "name": "Kuwait",
        "domain": "KW",
        "phone_code": 965,
        "mcc": "419",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Kuwait",
        "longitude": 48,
        "latitude": 29.3,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 158,
        "name": "Kyrgyzstan",
        "domain": "KG",
        "phone_code": 996,
        "mcc": "437",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Bishkek",
        "longitude": 74.46,
        "latitude": 42.54,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 159,
        "name": "Laos",
        "domain": "LA",
        "phone_code": 856,
        "mcc": "457",
        "min_mobile_num": 13,
        "max_mobile_num": 13,
        "capital": "Vientiane",
        "longitude": 102.36,
        "latitude": 17.58,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 160,
        "name": "Latvia",
        "domain": "LV",
        "phone_code": 371,
        "mcc": "247",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Riga",
        "longitude": 24.08,
        "latitude": 56.53,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 161,
        "name": "Lebanon",
        "domain": "LB",
        "phone_code": 961,
        "mcc": "415",
        "min_mobile_num": 10,
        "max_mobile_num": 11,
        "capital": "Beirut",
        "longitude": 35.31,
        "latitude": 33.53,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 162,
        "name": "Lesotho",
        "domain": "LS",
        "phone_code": 266,
        "mcc": "651",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Maseru",
        "longitude": 27.3,
        "latitude": -29.18,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 163,
        "name": "Liberia",
        "domain": "LR",
        "phone_code": 231,
        "mcc": "618",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Monrovia",
        "longitude": -10.47,
        "latitude": 6.18,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 164,
        "name": "Libya",
        "domain": "LY",
        "phone_code": 218,
        "mcc": "606",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Tripoli",
        "longitude": 13.07,
        "latitude": 32.49,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 165,
        "name": "Liechtenstein",
        "domain": "LI",
        "phone_code": 423,
        "mcc": "295",
        "min_mobile_num": 10,
        "max_mobile_num": 15,
        "capital": "Vaduz",
        "longitude": 9.31,
        "latitude": 47.08,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 166,
        "name": "Lithuania",
        "domain": "LT",
        "phone_code": 370,
        "mcc": "246",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Vilnius",
        "longitude": 25.19,
        "latitude": 54.38,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 167,
        "name": "Luxembourg",
        "domain": "LU",
        "phone_code": 352,
        "mcc": "270",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Luxembourg",
        "longitude": 6.09,
        "latitude": 49.37,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 169,
        "name": "Macau",
        "domain": "MO",
        "phone_code": 853,
        "mcc": "455",
        "min_mobile_num": 10,
        "max_mobile_num": 12,
        "capital": "Macau",
        "longitude": 113.33,
        "latitude": 22.12,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 170,
        "name": "Macedonia (Yugoslavia)",
        "domain": "MK",
        "phone_code": 389,
        "mcc": "294",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Skopje",
        "longitude": 21.26,
        "latitude": 42.01,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 171,
        "name": "Malawi",
        "domain": "MW",
        "phone_code": 265,
        "mcc": "650",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Lilongwe",
        "longitude": 33.48,
        "latitude": -14,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 173,
        "name": "Malaysia",
        "domain": "MY",
        "phone_code": 60,
        "mcc": "502",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Kuala Lumpur",
        "longitude": 101.41,
        "latitude": 3.09,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 175,
        "name": "Maldives",
        "domain": "MV",
        "phone_code": 960,
        "mcc": "472",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Male",
        "longitude": 73.28,
        "latitude": 4,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 177,
        "name": "Mali",
        "domain": "ML",
        "phone_code": 223,
        "mcc": "610",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Bamako",
        "longitude": -7.55,
        "latitude": 12.34,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 178,
        "name": "Malta",
        "domain": "MT",
        "phone_code": 356,
        "mcc": "278",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Valletta",
        "longitude": 14.31,
        "latitude": 35.54,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 179,
        "name": "Marshall Islands",
        "domain": "MH",
        "phone_code": 692,
        "mcc": "551",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 180,
        "name": "Martinique",
        "domain": "MQ",
        "phone_code": 596,
        "mcc": "340",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Fort-de-France",
        "longitude": -61.02,
        "latitude": 14.36,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 181,
        "name": "Mauritania",
        "domain": "MR",
        "phone_code": 222,
        "mcc": "609",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Nouakchott",
        "longitude": 57.3,
        "latitude": -20.1,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 182,
        "name": "Mauritius",
        "domain": "MU",
        "phone_code": 230,
        "mcc": "617",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 183,
        "name": "Mayotte Island",
        "domain": "YT",
        "phone_code": 262,
        "mcc": "647",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Mamoudzou",
        "longitude": 45.14,
        "latitude": -12.48,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 184,
        "name": "Mexico",
        "domain": "MX",
        "phone_code": 52,
        "mcc": "334",
        "min_mobile_num": 12,
        "max_mobile_num": 13,
        "capital": "Mexico",
        "longitude": -99.1,
        "latitude": 19.2,
        "region_id": 9,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 185,
        "name": "Micronesia",
        "domain": "FM",
        "phone_code": 691,
        "mcc": "550",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Palikir",
        "longitude": 158.09,
        "latitude": 6.55,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 186,
        "name": "Moldova",
        "domain": "MD",
        "phone_code": 373,
        "mcc": "259",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Chisinau",
        "longitude": 28.5,
        "latitude": 47.02,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 187,
        "name": "Monaco",
        "domain": "MC",
        "phone_code": 377,
        "mcc": "212",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 188,
        "name": "Mongolia",
        "domain": "MN",
        "phone_code": 976,
        "mcc": "428",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 189,
        "name": "Montserrat",
        "domain": "MS",
        "phone_code": 1664,
        "mcc": "354",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 190,
        "name": "Morocco",
        "domain": "MA",
        "phone_code": 212,
        "mcc": "604",
        "min_mobile_num": 11,
        "max_mobile_num": 13,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 192,
        "name": "Mozambique",
        "domain": "MZ",
        "phone_code": 258,
        "mcc": "643",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Maputo",
        "longitude": 32.32,
        "latitude": -25.58,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 193,
        "name": "Myanmar",
        "domain": "MM",
        "phone_code": 95,
        "mcc": "414",
        "min_mobile_num": 10,
        "max_mobile_num": 12,
        "capital": "Yangon",
        "longitude": 96.2,
        "latitude": 16.45,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 194,
        "name": "Namibia",
        "domain": "NA",
        "phone_code": 264,
        "mcc": "649",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Windhoek",
        "longitude": 17.04,
        "latitude": -22.35,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 195,
        "name": "Nauru",
        "domain": "NR",
        "phone_code": 674,
        "mcc": "536",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 196,
        "name": "Nepal",
        "domain": "NP",
        "phone_code": 977,
        "mcc": "429",
        "min_mobile_num": 0,
        "max_mobile_num": 13,
        "capital": "Kathmandu",
        "longitude": 85.2,
        "latitude": 27.45,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 197,
        "name": "Netherlands",
        "domain": "NL",
        "phone_code": 31,
        "mcc": "204",
        "min_mobile_num": 10,
        "max_mobile_num": 13,
        "capital": "Amsterdam",
        "longitude": 4.54,
        "latitude": 52.23,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 200,
        "name": "New Caledonia",
        "domain": "NC",
        "phone_code": 687,
        "mcc": "546",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Noumea",
        "longitude": 166.3,
        "latitude": -22.17,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 201,
        "name": "New Zealand",
        "domain": "NZ",
        "phone_code": 64,
        "mcc": "530",
        "min_mobile_num": 10,
        "max_mobile_num": 12,
        "capital": "Wellington",
        "longitude": 174.46,
        "latitude": -41.19,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 202,
        "name": "Nicaragua",
        "domain": "NI",
        "phone_code": 505,
        "mcc": "710",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Managua",
        "longitude": -86.2,
        "latitude": 12.06,
        "region_id": 5,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 203,
        "name": "Niger",
        "domain": "NE",
        "phone_code": 227,
        "mcc": "614",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Niamey",
        "longitude": 2.06,
        "latitude": 13.27,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 204,
        "name": "Nigeria",
        "domain": "NG",
        "phone_code": 234,
        "mcc": "621",
        "min_mobile_num": 13,
        "max_mobile_num": 13,
        "capital": "Abuja",
        "longitude": 7.32,
        "latitude": 9.05,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 205,
        "name": "Niue",
        "domain": "NU",
        "phone_code": 683,
        "mcc": "555",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 206,
        "name": "Norfolk Island",
        "domain": "NF",
        "phone_code": 672,
        "mcc": "505",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Kingston",
        "longitude": 168.43,
        "latitude": -45.2,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 207,
        "name": "Northern Mariana Islands",
        "domain": "MP",
        "phone_code": 1670,
        "mcc": "534",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Saipan",
        "longitude": 145.45,
        "latitude": 15.12,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 208,
        "name": "Norway",
        "domain": "NO",
        "phone_code": 47,
        "mcc": "242",
        "min_mobile_num": 10,
        "max_mobile_num": 14,
        "capital": "Oslo",
        "longitude": 10.45,
        "latitude": 59.55,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 209,
        "name": "Oman",
        "domain": "OM",
        "phone_code": 968,
        "mcc": "422",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Masqat",
        "longitude": 58.36,
        "latitude": 23.37,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 210,
        "name": "Pakistan",
        "domain": "PK",
        "phone_code": 92,
        "mcc": "410",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Islamabad",
        "longitude": 73.1,
        "latitude": 33.4,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 211,
        "name": "Palau",
        "domain": "PW",
        "phone_code": 680,
        "mcc": "552",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Koror",
        "longitude": 134.28,
        "latitude": 7.2,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 212,
        "name": "Bonaire, St Eustatius & Saba",
        "domain": "PW",
        "phone_code": 599,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 213,
        "name": "Panama",
        "domain": "PA",
        "phone_code": 507,
        "mcc": "714",
        "min_mobile_num": 10,
        "max_mobile_num": 11,
        "capital": "Panama",
        "longitude": -79.25,
        "latitude": 9,
        "region_id": 5,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 214,
        "name": "Papua New Guinea",
        "domain": "PG",
        "phone_code": 675,
        "mcc": "537,780",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Port Moresby",
        "longitude": 147.08,
        "latitude": -9.24,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 215,
        "name": "Paraguay",
        "domain": "PY",
        "phone_code": 595,
        "mcc": "744",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Asuncion",
        "longitude": -57.3,
        "latitude": -25.1,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 216,
        "name": "Peru",
        "domain": "PE",
        "phone_code": 51,
        "mcc": "716",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Lima",
        "longitude": -77,
        "latitude": -12,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 217,
        "name": "Philippines",
        "domain": "PH",
        "phone_code": 63,
        "mcc": "515",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Manila",
        "longitude": 121.03,
        "latitude": 14.4,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 219,
        "name": "Pitcairn Island",
        "domain": "PN",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 220,
        "name": "Poland",
        "domain": "PL",
        "phone_code": 48,
        "mcc": "260",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Warsaw",
        "longitude": 21,
        "latitude": 52.13,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 223,
        "name": "Portugal",
        "domain": "PT",
        "phone_code": 351,
        "mcc": "268",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Lisbon",
        "longitude": -9.1,
        "latitude": 38.42,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 225,
        "name": "Puerto Rico",
        "domain": "PR",
        "phone_code": 1,
        "mcc": "330",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "San Juan",
        "longitude": -66.07,
        "latitude": 18.28,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 226,
        "name": "Qatar",
        "domain": "QA",
        "phone_code": 974,
        "mcc": "427",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Doha",
        "longitude": 51.35,
        "latitude": 25.15,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 227,
        "name": "Reunion",
        "domain": "RE",
        "phone_code": 262,
        "mcc": "647",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 228,
        "name": "Romania",
        "domain": "RO",
        "phone_code": 40,
        "mcc": "226",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Bucuresti",
        "longitude": 26.1,
        "latitude": 44.27,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 229,
        "name": "Russia",
        "domain": "RU",
        "phone_code": 7,
        "mcc": "250",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Moskva",
        "longitude": 37.35,
        "latitude": 55.45,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 232,
        "name": "Rwanda",
        "domain": "RW",
        "phone_code": 250,
        "mcc": "635",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Kigali",
        "longitude": 30.04,
        "latitude": -1.59,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 233,
        "name": "Saint Helena",
        "domain": "SH",
        "phone_code": 290,
        "mcc": "658",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 234,
        "name": "Saint Kitts And Nevis",
        "domain": "KN",
        "phone_code": 1869,
        "mcc": "356",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Basseterre",
        "longitude": -62.43,
        "latitude": 17.17,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 235,
        "name": "Saint Lucia",
        "domain": "LC",
        "phone_code": 1758,
        "mcc": "358",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Castries",
        "longitude": -60.58,
        "latitude": 14.02,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 236,
        "name": "Saint Pierre and Miquelon",
        "domain": "PM",
        "phone_code": 508,
        "mcc": "308",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Saint-Pierre",
        "longitude": -56.12,
        "latitude": 46.46,
        "region_id": 9,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 237,
        "name": "Saint Vincent and the Grenadines",
        "domain": "VC",
        "phone_code": 1784,
        "mcc": "360",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Kingstown",
        "longitude": -61.1,
        "latitude": 13.1,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 238,
        "name": "Samoa (American)",
        "domain": "AS",
        "phone_code": 1684,
        "mcc": "544",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Apia",
        "longitude": -171.5,
        "latitude": -13.5,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 239,
        "name": "San Marino",
        "domain": "SM",
        "phone_code": 378,
        "mcc": "292",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "San Marino",
        "longitude": 12.3,
        "latitude": 43.55,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 240,
        "name": "Sao Tome and Principe",
        "domain": "ST",
        "phone_code": 239,
        "mcc": "626",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Sao Tome",
        "longitude": 6.39,
        "latitude": 0.1,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 241,
        "name": "Saudi Arabia",
        "domain": "SA",
        "phone_code": 966,
        "mcc": "420",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Riyadh",
        "longitude": 46.42,
        "latitude": 24.41,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 242,
        "name": "Senegal",
        "domain": "SN",
        "phone_code": 221,
        "mcc": "608",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Dakar",
        "longitude": -17.29,
        "latitude": 14.34,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 243,
        "name": "Seychelles",
        "domain": "SC",
        "phone_code": 248,
        "mcc": "633",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 244,
        "name": "Sierra Leone",
        "domain": "SL",
        "phone_code": 232,
        "mcc": "619",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Freetown",
        "longitude": -13.17,
        "latitude": 8.3,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 245,
        "name": "Singapore",
        "domain": "SG",
        "phone_code": 65,
        "mcc": "525",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 248,
        "name": "Slovakia",
        "domain": "SK",
        "phone_code": 421,
        "mcc": "231",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Bratislava",
        "longitude": 17.07,
        "latitude": 48.1,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 249,
        "name": "Slovenia",
        "domain": "SI",
        "phone_code": 386,
        "mcc": "293",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Ljubljana",
        "longitude": 14.33,
        "latitude": 46.04,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 250,
        "name": "Solomon Islands",
        "domain": "SB",
        "phone_code": 677,
        "mcc": "540",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Honiara",
        "longitude": 159.57,
        "latitude": -9.27,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 251,
        "name": "Somalia",
        "domain": "SO",
        "phone_code": 252,
        "mcc": "637",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Mogadishu",
        "longitude": 45.25,
        "latitude": 2.02,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 252,
        "name": "South Africa",
        "domain": "ZA",
        "phone_code": 27,
        "mcc": "655",
        "min_mobile_num": 11,
        "max_mobile_num": 15,
        "capital": "Pretoria",
        "longitude": 28.12,
        "latitude": -25.44,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "ZA_MNP",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 253,
        "name": "Spain",
        "domain": "ES",
        "phone_code": 34,
        "mcc": "214",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Madrid",
        "longitude": -3.45,
        "latitude": 40.25,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 256,
        "name": "Sri Lanka",
        "domain": "LK",
        "phone_code": 94,
        "mcc": "413",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 257,
        "name": "Sudan (North), The Republic of the",
        "domain": "SD",
        "phone_code": 249,
        "mcc": "634",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Khartoum",
        "longitude": 32.35,
        "latitude": 15.31,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 258,
        "name": "Suriname",
        "domain": "SR",
        "phone_code": 597,
        "mcc": "746",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Paramaribo",
        "longitude": -55.1,
        "latitude": 5.5,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 259,
        "name": "Swaziland",
        "domain": "SZ",
        "phone_code": 268,
        "mcc": "653",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Mbabane",
        "longitude": 31.06,
        "latitude": -26.18,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 260,
        "name": "Sweden",
        "domain": "SE",
        "phone_code": 46,
        "mcc": "240",
        "min_mobile_num": 9,
        "max_mobile_num": 15,
        "capital": "Stockholm",
        "longitude": 18.03,
        "latitude": 59.2,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 262,
        "name": "Switzerland",
        "domain": "CH",
        "phone_code": 41,
        "mcc": "228",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Bern",
        "longitude": 7.28,
        "latitude": 46.57,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 263,
        "name": "Syria",
        "domain": "SY",
        "phone_code": 963,
        "mcc": "417",
        "min_mobile_num": 9,
        "max_mobile_num": 12,
        "capital": "Damascus",
        "longitude": 36.18,
        "latitude": 33.3,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 264,
        "name": "Taiwan",
        "domain": "TW",
        "phone_code": 886,
        "mcc": "466",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 121,
        "latitude": 23.46,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 267,
        "name": "Tajikistan",
        "domain": "TJ",
        "phone_code": 992,
        "mcc": "436",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Dushanbe",
        "longitude": 68.48,
        "latitude": 38.33,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 268,
        "name": "Tanzania",
        "domain": "TZ",
        "phone_code": 255,
        "mcc": "640",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Dodoma",
        "longitude": 35.45,
        "latitude": -6.08,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 269,
        "name": "Thailand",
        "domain": "TH",
        "phone_code": 66,
        "mcc": "520",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Bangkok",
        "longitude": 100.35,
        "latitude": 13.45,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 271,
        "name": "Togo",
        "domain": "TG",
        "phone_code": 228,
        "mcc": "615",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Lome",
        "longitude": 1.2,
        "latitude": 6.09,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 272,
        "name": "Tokelau",
        "domain": "TK",
        "phone_code": 690,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 273,
        "name": "Tonga Islands",
        "domain": "TO",
        "phone_code": 676,
        "mcc": "539",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Nuku'alofa",
        "longitude": -174,
        "latitude": -21.1,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 274,
        "name": "Trinidad and Tobago",
        "domain": "TT",
        "phone_code": 1868,
        "mcc": "374",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Port of Spain",
        "longitude": -61.31,
        "latitude": 10.4,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 275,
        "name": "Tunisia",
        "domain": "TN",
        "phone_code": 216,
        "mcc": "605",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Tunis",
        "longitude": 10.11,
        "latitude": 36.5,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 276,
        "name": "Turkey",
        "domain": "TR",
        "phone_code": 90,
        "mcc": "286",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Ankara",
        "longitude": 32.54,
        "latitude": 39.57,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 277,
        "name": "Turkmenistan",
        "domain": "TM",
        "phone_code": 993,
        "mcc": "438",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Ashgabat",
        "longitude": 57.5,
        "latitude": 38,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 278,
        "name": "Turks and Caicos Islands",
        "domain": "TC",
        "phone_code": 1649,
        "mcc": "376",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 279,
        "name": "Tuvalu",
        "domain": "TV",
        "phone_code": 688,
        "mcc": "553",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Funafuti",
        "longitude": 179.13,
        "latitude": -8.31,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 280,
        "name": "Uganda",
        "domain": "UG",
        "phone_code": 256,
        "mcc": "641",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Kampala",
        "longitude": 32.3,
        "latitude": 0.2,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 281,
        "name": "Ukraine",
        "domain": "UA",
        "phone_code": 380,
        "mcc": "255",
        "min_mobile_num": 10,
        "max_mobile_num": 12,
        "capital": "Kiev (Rus)",
        "longitude": 30.28,
        "latitude": 50.3,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 284,
        "name": "USA",
        "domain": "US",
        "phone_code": 1,
        "mcc": "311,312,316,071,310",
        "min_mobile_num": 10,
        "max_mobile_num": 12,
        "capital": "Washington DC",
        "longitude": -77.02,
        "latitude": 39.91,
        "region_id": 9,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 289,
        "name": "United Arab Emirates",
        "domain": "AE",
        "phone_code": 971,
        "mcc": "424",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Abu Dhabi",
        "longitude": 54.22,
        "latitude": 24.28,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "Tyntec",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 290,
        "name": "Uruguay",
        "domain": "UY",
        "phone_code": 598,
        "mcc": "748",
        "min_mobile_num": 11,
        "max_mobile_num": 11,
        "capital": "Montevideo",
        "longitude": -56.11,
        "latitude": -34.5,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 291,
        "name": "Uzbekistan",
        "domain": "UZ",
        "phone_code": 998,
        "mcc": "434",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Tashkent",
        "longitude": 69.1,
        "latitude": 41.2,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 292,
        "name": "Vanuatu",
        "domain": "VU",
        "phone_code": 678,
        "mcc": "541",
        "min_mobile_num": 8,
        "max_mobile_num": 10,
        "capital": "Port-Vila",
        "longitude": 168.18,
        "latitude": -17.45,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 293,
        "name": "Vatican City State",
        "domain": "VA",
        "phone_code": 39,
        "mcc": "222",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 294,
        "name": "Venezuela",
        "domain": "VE",
        "phone_code": 58,
        "mcc": "734",
        "min_mobile_num": 12,
        "max_mobile_num": 13,
        "capital": "Caracas",
        "longitude": -66.55,
        "latitude": 10.3,
        "region_id": 10,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 295,
        "name": "Vietnam",
        "domain": "VN",
        "phone_code": 84,
        "mcc": "452",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Hanoi",
        "longitude": 105.55,
        "latitude": 21.05,
        "region_id": 3,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 296,
        "name": "Aland Islands",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 4,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 297,
        "name": "US Virgin Islands",
        "domain": "VI",
        "phone_code": 1340,
        "mcc": "332,376",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Charlotte Amalie",
        "longitude": -64.56,
        "latitude": 18.21,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 298,
        "name": "Wallis and Futuna Islands",
        "domain": "WF",
        "phone_code": 681,
        "mcc": "543",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 299,
        "name": "Yemen",
        "domain": "YE",
        "phone_code": 967,
        "mcc": "421",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 303,
        "name": "Zambia",
        "domain": "ZM",
        "phone_code": 260,
        "mcc": "645",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Lusaka",
        "longitude": 28.16,
        "latitude": -15.28,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 304,
        "name": "Zanzibar",
        "domain": "",
        "phone_code": 259,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 305,
        "name": "Zimbabwe",
        "domain": "ZW",
        "phone_code": 263,
        "mcc": "648",
        "min_mobile_num": 12,
        "max_mobile_num": 13,
        "capital": "Harare",
        "longitude": 31.02,
        "latitude": -17.43,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "XConnect",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": "LCR"
      },
      {
        "countryID": 310,
        "name": "European Union",
        "domain": "EU",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 6,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 311,
        "name": "Netherlands Antilles",
        "domain": "AN",
        "phone_code": 599,
        "mcc": "362",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Willemstad",
        "longitude": -69,
        "latitude": 12.05,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 312,
        "name": "Ethiopia",
        "domain": "ET",
        "phone_code": 251,
        "mcc": "636",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Addis Ababa",
        "longitude": 38.42,
        "latitude": 9.02,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 313,
        "name": "Mecau",
        "domain": "",
        "phone_code": 853,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 314,
        "name": "Globalstar",
        "domain": "",
        "phone_code": 1,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 315,
        "name": "Madagascar",
        "domain": "MG",
        "phone_code": 261,
        "mcc": "646",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Antananarivo",
        "longitude": 47.31,
        "latitude": -18.55,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 316,
        "name": "Guernsey",
        "domain": "GG",
        "phone_code": 44,
        "mcc": "234",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "St. Peter Port",
        "longitude": -2.33,
        "latitude": 49.26,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 318,
        "name": "Angola",
        "domain": "AO",
        "phone_code": 244,
        "mcc": "631",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "Luanda",
        "longitude": 13.15,
        "latitude": -8.5,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 319,
        "name": "Aruba",
        "domain": "AW",
        "phone_code": 297,
        "mcc": "363",
        "min_mobile_num": 10,
        "max_mobile_num": 10,
        "capital": "Oranjestad",
        "longitude": -70.02,
        "latitude": 12.32,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 320,
        "name": "zN/A 1",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 321,
        "name": "Australian External Territories",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 322,
        "name": "ZZ Unallocated Telcos",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 323,
        "name": "Global Mobile Satellite",
        "domain": "fake",
        "phone_code": 881,
        "mcc": "998",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 324,
        "name": "Inmarsat Atlantic OW",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 325,
        "name": "Serbia",
        "domain": "RS",
        "phone_code": 381,
        "mcc": "220",
        "min_mobile_num": 11,
        "max_mobile_num": 12,
        "capital": "Belgrade",
        "longitude": 20.37,
        "latitude": 44.5,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 326,
        "name": "Heard and McDonald Islands",
        "domain": "HM",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 74,
        "latitude": -53,
        "region_id": 11,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 327,
        "name": "Byelorussian SSR",
        "domain": "BY",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 2,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 328,
        "name": "Neutral Zone",
        "domain": "NT",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 329,
        "name": "South Georgia",
        "domain": "GS",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 11,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 330,
        "name": "Svalbard",
        "domain": "SJ",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "Longyearbyen",
        "longitude": 22,
        "latitude": 78,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 331,
        "name": "US Minor Outlying Islands",
        "domain": "UM",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 332,
        "name": "Jersey",
        "domain": "JE",
        "phone_code": 44,
        "mcc": "234",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 1,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 333,
        "name": "Western Sahara",
        "domain": "EH",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 334,
        "name": "Palestine",
        "domain": "PS",
        "phone_code": 970,
        "mcc": "425",
        "min_mobile_num": 12,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 7,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 335,
        "name": "Satellite Networks",
        "domain": "",
        "phone_code": 882,
        "mcc": "901,902",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 336,
        "name": "Satellite-Other",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 337,
        "name": "Dominica",
        "domain": "DM",
        "phone_code": 1767,
        "mcc": "366",
        "min_mobile_num": 10,
        "max_mobile_num": 12,
        "capital": "Roseau",
        "longitude": -61.24,
        "latitude": 15.2,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "NetNumber",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 338,
        "name": "Canary Islands",
        "domain": "",
        "phone_code": 34,
        "mcc": "214",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": -15.24,
        "latitude": 28.06,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 339,
        "name": "Montenegro",
        "domain": "ME",
        "phone_code": 382,
        "mcc": "297",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 340,
        "name": "Saipan",
        "domain": "MP",
        "phone_code": 1670,
        "mcc": "",
        "min_mobile_num": null,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 341,
        "name": "Samoa (Western)",
        "domain": "WS",
        "phone_code": 685,
        "mcc": "549",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Apia",
        "longitude": -170.43,
        "latitude": 0,
        "region_id": 8,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 342,
        "name": "zN/A 3",
        "domain": "",
        "phone_code": null,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 343,
        "name": "Kosovo",
        "domain": "RKS",
        "phone_code": 381,
        "mcc": "993",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 6,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 344,
        "name": "Abkhazia",
        "domain": "RU",
        "phone_code": 7940,
        "mcc": "289",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Sukhumi",
        "longitude": 41.05,
        "latitude": 43.12,
        "region_id": 6,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 345,
        "name": "Sudan (South), Republic of",
        "domain": "SS",
        "phone_code": 211,
        "mcc": "659",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "Khartoum",
        "longitude": 32.35,
        "latitude": 15.31,
        "region_id": 1,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 346,
        "name": "Saint Martin",
        "domain": "fake",
        "phone_code": 590,
        "mcc": "172,721,999",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 347,
        "name": "Sint Maarten",
        "domain": "AN",
        "phone_code": 1721,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 12,
        "capital": "",
        "longitude": 18.07,
        "latitude": -63,
        "region_id": 4,
        "visible_on_site": 1,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 348,
        "name": "CTI",
        "domain": "",
        "phone_code": 999,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 0,
        "capital": "REFER CLIFF",
        "longitude": 0,
        "latitude": 0,
        "region_id": 0,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 349,
        "name": "Coral Sea Islands",
        "domain": "AU",
        "phone_code": 677,
        "mcc": "",
        "min_mobile_num": 0,
        "max_mobile_num": 13,
        "capital": "Willis Island",
        "longitude": 149.9,
        "latitude": -16.3,
        "region_id": 8,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 350,
        "name": "Jersey Telecom M2M (+882)",
        "domain": "JE",
        "phone_code": 882,
        "mcc": "234",
        "min_mobile_num": 12,
        "max_mobile_num": 15,
        "capital": "",
        "longitude": 0,
        "latitude": 0,
        "region_id": 6,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      },
      {
        "countryID": 351,
        "name": "Tristan da Cuhna",
        "domain": "TA",
        "phone_code": 290,
        "mcc": "",
        "min_mobile_num": 8,
        "max_mobile_num": 8,
        "capital": "",
        "longitude": null,
        "latitude": null,
        "region_id": 1,
        "visible_on_site": 0,
        "nnp_status": 0,
        "nnp_supplier": "",
        "route performance index threshold": 75,
        "route performance index bracket over": 10,
        "route performance index bracket under": 5,
        "non company group routing method": ""
      }
     ]

  }

  stepOne(){
    this.firstSection = false;
    this.secondSection = true;
    this.thirdSection = false;
  }


  prevStep(){
    this.firstSection = true;
    this.secondSection = false;
    this.thirdSection = false;
  }

  stepTwo(){
    this.firstSection = false;
    this.secondSection = false;
    this.thirdSection = true;
  }


  stepThree(){
    this.firstSection = false;
    this.secondSection = true;
    this.thirdSection = false;
  }

  registerUser(billingProfile: number){

    let mobile = this.registerForm.controls['cellPhone'].value;
    let firstName = this.registerForm.controls['firstName'].value;
    let lastName = this.registerForm.controls['lastName'].value;
    let company = this.registerForm.controls['company'].value;
    let username = this.registerForm.controls['username'].value;
    let email = this.registerForm.controls['emailAddress'].value;
    let country = this.countryObject?.countryID;
    this.newBillingProfile = billingProfile;

    // Generate random password
    var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 8;
    var password = "";

    for(var i = 0; i <= passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    password = this.addAnyMissingPasswordRules(password, chars);
        var newObject:any = new Array();
        newObject['forcecreate'] = 1;
        newObject['IpAddress'] = '';
        newObject['username'] = btoa(username);
        newObject['password'] = password;
        newObject['mobfull'] = this.formatedPhoneNumber;
        newObject['email'] = btoa(email);
        newObject['company'] = btoa(company);
        newObject['fname'] = btoa(firstName);
        newObject['sname'] = btoa(lastName);
        newObject['Web'] = 1;
        newObject['product'] = 2;
        newObject['profile'] = String(this.newBillingProfile);
        newObject['countryID'] = String(country);

        let content = 'register';
        let type = 'serialize';
        let filter = serialize(newObject);
        let newFilter = btoa(filter);

        this.userLogin.signupUser(content, type, newFilter, this.contentapi).subscribe((response: any) => {
          if(unserialize(response) === 'ERR: Username is unavailable.'){
            this.responseData = "Error: Username is unavailable.";
            this.buttonHide = false;
            this.toggleResponseMessage = true;
          }
          else if(unserialize(response) === 'ERR: Registering from an IP address located in a blocked country.'){
            this.responseData = 'Error: Registering from an IP address located in a blocked country.';
            this.buttonHide = false;
            this.toggleResponseMessage = true;
          }
          else if(unserialize(response) === 'ERR: Action could not be executed.'){
            this.responseData = 'Error: Action could not be executed.';
            this.buttonHide = false;
            this.toggleResponseMessage = true;
          }
          else if(unserialize(response) === 'ERR: FirstName Character length must be between 0 and 80.'){
            this.responseData = 'Error: FirstName Character length must be between 0 and 80.';
            this.buttonHide = false;
            this.toggleResponseMessage = true;
          }
          else if(unserialize(response) === 'ERR: Registering for a blocked country.'){
            this.responseData = 'Error: Registering for a blocked country.';
            this.buttonHide = false;
            this.toggleResponseMessage = true;
          }
          else if(unserialize(response) === "OK"){
            this.responseData = "Success: Please check your emails for confirmation.";
            this.buttonHide = true;
            this.toggleResponseMessage = false;
          }
          else{
            this.responseData = unserialize(response);
            this.buttonHide = false;
            this.toggleResponseMessage = true;
          }

        },(error) => {
          console.log(error);
        });

  }


  outFocus(){
    this.displayCountries = false;
  }

  onFocus(){
    this.displayCountries = true;
  }


  public selectedCountry(countryID: any, countryName: any, countryDomain: any){
    let newCountryID = countryID;
    let newCountryName = countryName;
    let newCountryDomain = countryDomain;

    this.countryObject =  {
      countryID: newCountryID,
      countryName: newCountryName,
      countryDomain: newCountryDomain
    }


    // Check if user has added the the country already.
    if(this.selectedCountryOptions.some((e: any) => e.countryName === countryName) === false){

      this.selectedCountryOptions.push(this.countryObject);


      let element1 = {name: "usa"}
      let element2 = {name: "mexico"}


      if(this.selectedCountryOptions.length === 1) // One Country selected.
      {


        if(this.selectedCountryOptions[0].countryName.toLowerCase() == 'south africa') // Set SA Local Account
        {
          this.accountType = 'SALocalAccount';
          this.applicationType = '';
          this.billingProfile = 2;
          // console.log('One Country South Africa');
        }
        else if(this.selectedCountryOptions[0].countryName.toLowerCase() == 'usa')
        {
          this.accountType = 'IsUsLocalAccount';
          this.applicationType = '';
          this.billingProfile = 9410; // Created number because the user needs to choose a type of US product
          // console.log('One Country usa');
        }
        else{
          this.accountType = 'InternationalAccount';
          this.applicationType = '';
          this.billingProfile = 1;
          // console.log('One Country else International');
        }

      }
      else if(this.selectedCountryOptions.length === 2 && this.selectedCountryOptions.some(((country:any) => country.countryName.toLowerCase() === element1.name) && ((country:any) => country.countryName.toLowerCase() === element2.name))) // USA and Mexico ONLY selected.
      {
        console.log('USA and Mexico');
        this.accountType = '';
        this.applicationType = '';
        this.billingProfile = 9410; // Created number because the user needs to choose a type of US product
      }
      else // More than one country selected and not only USA and mexico
      {
        this.accountType = 'InternationalAccount';
        this.applicationType = '';
        this.billingProfile = 1;
        // console.log('More than one country selected and not only USA and mexico');
      }

      this.outFocus();
    }


  }
  // we want to enforce that our password rules are added if missing
  private addAnyMissingPasswordRules(password: any, chars: string)
  {
     // declarations
     var pattern = null;
     const numbers = '0123456789';
     const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
     const symbols = '!@#$%^*()_-=[]{}\\\|/?,.;:';

     // add missing numbers
     pattern = /[0-9]{1}/;

     if (!pattern.test(password))
     {
         password = this.genRandomChar(password, numbers, numbers.length);
     }

     // add missing uppercase letters
     pattern = /[A-Z]{1}/;

     if (!pattern.test(password))
     {
        password = this.genRandomChar(password, letters, letters.length);
     }

     // add missing symbols
     pattern = /[\W]{1}/;

     if (!pattern.test(password))
     {
       password = this.genRandomChar(password, symbols, symbols.length);
     }

     return password;
  }

  // we want to add a char at a random positon of the string
  genRandomChar(password: string, chars: string,  len = 10)
  {
      if (password != null || password != '')
      {
          var pass = password.split('')
          var c = chars[Math.floor(Math.random() * len)];
          var key = Math.floor(Math.random() * password.length);
          var position = Math.floor(Math.random() * 2);

          switch(position) {
            case 0:
              pass[key] = (c + password[key]);
              break;
            case 1:
              pass[key] = (password[key] + c);
              break;
          }
          return pass.join('');
      }
      return null;
  }



  public removeCountry(index:number)
  {
    this.selectedCountryOptions.splice(index, 1);
  }



  public toggleEditable(event: any)
  {
    if ( event.target.checked == true ) {
      this.hideMultiSelect = true;

      this.accountType = 'InternationalAccount';
      this.applicationType = '';
      this.billingProfile = 1;
    }
    else{
      this.hideMultiSelect = false;
      this.selectedCountryOptions = [];
    }
  }


  public checkUernameAvailability(fname: any, lname: any, ucompany: any, uname: any): void{

    let firstname = fname.value;
    let lastname = lname.value;
    let company = ucompany.value;
    let username = uname.value;

    if(username != '' && firstname != '' && lastname != '' && company != ''){

      var newObject:any = new Array();
      newObject['username'] = btoa(username);
      newObject['company'] = company;
      newObject['fname'] = firstname;
      newObject['sname'] = lastname;

      let content = 'validateusername';
      let type = 'serialize';
      let filter = serialize(newObject);
      let newFilter = btoa(filter);

      //this.error_message = false;
      this.userLogin.validateUsername(content, type, newFilter, this.contentapi).subscribe((response: any) => {
        let responseData = unserialize(response);
        this.checkUsername = responseData;

        //console.log(responseData);
        if(responseData.Result === 'ERR: Username is unavailable.'){
          this.checkUsername = responseData;
        }
        else{
          this.checkUsername = {};
        }


      },(error) => {
        console.log(error);
      });

    }

  }



  public onKeyUp(event: any){
    let newNumber = this.registerForm.controls['cellPhone'].value;
    this.formatedPhoneNumber = newNumber?.e164Number;
    this.phoneNumberResponse = this.registerForm.controls['cellPhone'].errors;
  }


  public passwordValidation(event: any){

    this.validatePass = true;

    var value = event.target.value;
    var alpha_regExpression = new RegExp("^[A-Za-z]+$");
    var numeric_regExpression = new RegExp("[0-9]");

    if(alpha_regExpression.test(value) === true){
      this.alphaRegex = true;
    }
    else{
      this.alphaRegex = false;
    }

    if(numeric_regExpression.test(value) === true){
      this.numericRegex = true;
    }
    else{
      this.numericRegex = false;
    }

    if((value as string).indexOf(' ') >= 0){
      this.whitespaceValidate =  false;
    }
    else{
      this.whitespaceValidate =  true;
    }

  }


}
