<app-header></app-header>
    <div class="login-container pb-5">
        <h5>PAGE NOT FOUND</h5>
        <p class="mb-5">The page you have requested cannot be found. Please enter the correct url or click on the button below for the login page</p>
        <img src="/assets/searching.png" width="100%">
        <div class="text-center pt-5">
            <a class="login-links" [routerLink]="['/']">
                <input type="submit" value="Go to Login" class="loginBtn"/>
            </a>
        </div>
    </div>
<app-footer></app-footer>