<app-header></app-header>

    <div class="login-container pb-5" *ngIf="responseMessage !== 'OK'">

        <h5>Forgot your messaging password?</h5>

        <p>If you need additional assistance, please send an email to <a href="https://docs.clickatell.com/" style="color: #1889ae">Support.</a></p>

        <form [formGroup] = "recoverForm" id="recover-form" class="text-left pt-0">


            <div class="form-group my-3">
                <label for="Username">Username</label>
                <input type="text" formControlName="username" name="username" class="form-control" id="Username" [class.is-invalid]="recoverForm.get('username')!.invalid && recoverForm.get('username')!.touched" (focusout)="checkDuplicateAccount($event)">
            </div>

            <div *ngIf="dublicateUserCheck!.Error === 'Duplicate username - ClientID required.'" class="loginOptions pt-2">
                <small>Can you give us more info? Which account type did you sign up for?</small>

                <div class="form-check mt-4">

                    <input class="form-check-input" formControlName="accountType" type="radio" name="accountType" id="developerCentral" value="developer-central" (change)="eventCheck($event)">
                    <label class="form-check-label" for="developerCentral">
                    Developers' Central
                    </label>
                </div>
              
                <div class="form-check mb-4">
                    <input class="form-check-input" formControlName="accountType" type="radio" name="accountType" id="communicator2" value="communicator2" (change)="eventCheck($event)">
                    <label class="form-check-label" for="communicator2">
                    Communicator 2
                    </label>
                </div>

            </div>

            

            <div class="form-group my-3" *ngIf="dublicateUserCheck!.Error === 'Duplicate username - ClientID required.' && clientIDRequired === true">
                <label for="ClientID">Client ID</label>
                <input type="text" formControlName="clientid" name="clientid" class="form-control" id="clientid" [class.is-invalid]="recoverForm.get('clientid')!.invalid && recoverForm.get('clientid')!.touched">
            </div>

            <div class="form-group my-3">
                <label for="userEmail">Email</label>
                <input type="email" formControlName="userEmail" name="email" class="form-control" id="userEmail" [class.is-invalid]="recoverForm.get('userEmail')!.invalid && recoverForm.get('userEmail')!.touched">
            </div>

            <div class="alert alert-warning" *ngIf="loading" role="alert" id="alert" style="z-index: -1; text-align: center;">Please wait... <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="font-size: 20px"></i>
                <span class="sr-only">Loading...</span></div>

            <!--Login  using javascript-->
            <div class="alert alert-danger" *ngIf="error_message" role="alert" id="alert"  style="z-index: -1;">{{error_message_response}}</div>

            <!-- <div class="alert alert-success" *ngIf="success_message" role="alert" id="alert"  style="z-index: -1;">Success: Please check your email for your new Password.</div> -->

            <div class="text-center" id="submit-submit-button">
                <input type="submit" value="Submit" class="loginBtn" (click)="passwordReset()" [disabled]="!recoverForm.get('username')!.valid || !recoverForm.get('userEmail')!.valid"/>
            </div>
        </form>

        <div class="row mt-5">

            <div class="col-12 text-center">
                <a class="login-links" [routerLink]="['/login']">Back to Login</a>
            </div>

        </div>

    </div>


    <div class="signup-container pb-5" *ngIf="responseMessage === 'OK'">

        <h2 class="text-center">Thank you.</h2>
        <p>Your password has been sent to the email address you submitted. Please contact <a href="mailto:support@clickatell.com" style="text-decoration: none;">support@clickatell.com</a> should you experience any further problems.</p>

    </div>


<app-footer></app-footer>