import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public href: string = "";

  constructor(private router: Router) { }

  ngOnInit(): void {

    this.router.url;

    if(this.router.url == '/login' || this.router.url == '/'){
      this.href = 'Developer Central / Communicator login'
    }
    else if(this.router.url == '/forgot-password' ){
      this.href = 'Developer Central / Communicator Forgot Password'
    }
    else if(this.router.url == '/sign-up' ){
      this.href = 'Developer Central Signup'
    }
    // else if(this.router.url == '/central-login' ){
    //   this.href = 'Developers Central Login'
    // }

  }

}
