<app-header></app-header>


    <div class="signup-container pb-5" *ngIf="responseData !== 'Success: Please check your emails for confirmation.'">

        <h5>Sign up for a free account</h5>

        <p>Get started in 3 easy steps</p>

        <div class="row text-center py-4 step-sections">
            <div class="col-12 col-md-4 col-lg-4">
                <span [class.active] = "firstSection === true">Step 1</span>
            </div>

            <div class="col-12 col-md-4 col-lg-4">
                <span [class.active] = "secondSection === true">Step 2</span>
            </div>

            <div class="col-12 col-md-4 col-lg-4">
                <span [class.active] = "thirdSection === true">Step 3</span>
            </div>
        </div>

        <form [formGroup] = "registerForm" id="form" class="text-left pt-0" autocomplete="off">


            <div class="step1" *ngIf="firstSection">

                <div class="row">
                    <div class="form-group my-3 col-12 col-md-6 col-lg-6">
                        <label for="firstName">First Name</label>
                        <input type="text" name="firstName" class="form-control" formControlName="firstName" id="firstName" [class.is-invalid]="registerForm.get('firstName')!.invalid && registerForm.get('firstName')!.touched" #fname (focusout)="checkUernameAvailability(fname, lname, company, username)">
                    </div>

                    <div class="form-group my-3 col-12 col-md-6 col-lg-6">
                        <label for="lastName">Last Name</label>
                        <input type="text" name="lastName"  formControlName="lastName" class="form-control" id="lastName" [class.is-invalid]="registerForm.get('lastName')!.invalid && registerForm.get('lastName')!.touched" #lname (focusout)="checkUernameAvailability(fname, lname, company, username)">
                    </div>

                    <div class="form-group my-3 col-12 col-md-6 col-lg-6">
                        <label for="company">Company</label>
                        <input type="text" name="company" formControlName="company" class="form-control" id="company" [class.is-invalid]="registerForm.get('company')!.invalid && registerForm.get('company')!.touched" #company (focusout)="checkUernameAvailability(fname, lname, company, username)">
                    </div>

                    <div class="form-group my-3 col-12 col-md-6 col-lg-6">
                        <label for="username">Username</label>
                        <input type="text" name="username" formControlName="username" class="form-control" id="username" [class.is-invalid]="registerForm.get('username')!.invalid && registerForm.get('username')!.touched || checkUsername!.Result === 'ERR: Username is unavailable.'" #username (focusout)="checkUernameAvailability(fname, lname, company, username)">
                        
                    </div>

                    <div *ngIf="checkUsername!.Result === 'ERR: Username is unavailable.'" class="alert alert-warning" role="alert" style="margin: 10px 10px; width: 98%;">
                        Username is unavailable.<br/>
                        
                        Suggestions:

                        <span *ngFor="let suggest of checkUsername!.Suggestions | keyvalue">
                            <small style="background: rgba(0,0,0,0.07); padding: 5px; margin: 0px 5px">{{suggest.value}}</small>
                        </span>
                    </div>

                    <div class="form-group my-3 col-12 col-md-6 col-lg-6">
                        <label for="cellPhone">Cellphone number</label><br/>
                        <ngx-intl-tel-input
                            [cssClass]="'custom'"
                            [preferredCountries]="[CountryISO.UnitedStates, CountryISO.UnitedKingdom, CountryISO.SouthAfrica]"
                            [enableAutoCountrySelect]="false"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                            [selectFirstCountry]="false"
                            [selectedCountryISO]="CountryISO.UnitedStates"
                            [phoneValidation]="true"
                            name="cellPhone"
                            formControlName="cellPhone"
                            (keyup)="onKeyUp($event)"
                            [class.is-invalid]="phoneNumberResponse?.validatePhoneNumber?.valid == false && registerForm.get('cellPhone')!.touched"
                            >
                        </ngx-intl-tel-input>

                        <small *ngIf="phoneNumberResponse?.validatePhoneNumber?.valid === false" style="color: #dc3545"><i class="fa fa-exclamation-circle" style="color: #dc3545" aria-hidden="true"></i> Please enter valid contact number</small>
                    </div>

                    <div class="form-group my-3 col-12 col-md-6 col-lg-6">
                        <label for="emailAddress">Email Address</label>
                        <input type="text" name="emailAddress" formControlName="emailAddress" class="form-control" id="emailAddress" [class.is-invalid]="registerForm.get('emailAddress')!.invalid && registerForm.get('emailAddress')!.touched">
                    </div>


                </div>

                <div class="text-center">
                    <input type="submit" value="Next" class="nextBtn" (click)="stepOne()" [disabled]="!registerForm.get('firstName')!.valid || !registerForm.get('lastName')!.valid || !registerForm.get('company')!.valid || !registerForm.get('username')!.valid || phoneNumberResponse?.validatePhoneNumber?.valid == false || !registerForm.get('emailAddress')!.valid || checkUsername!.Result === 'ERR: Username is unavailable.'"/>
                </div>

            </div>


            <div class="step2 py-4" *ngIf="secondSection">
                
                <h5> I want to send my messages to: </h5>

                <div class="form-check mt-5">
                    <input type="checkbox" class="form-check-input" name="anywhereInput" formControlName="anywhereInput" id="anywhereInput" (change)="toggleEditable($event)">
                    <label class="form-check-label" for="AnywhereInput">Anywhere in the world</label>
                </div>


                <div class="mt-5" *ngIf="hideMultiSelect === false">


                    <label class="mb-3" for="Username">Type on the field below to add multiple country</label>
                    

                    <div id="myDropdown" class="dropdown-content">
                        <input type="text" placeholder="Search.." id="myInput" onkeyup="filterFunction()" (focus)="onFocus()" autocomplete="off">
                        <div id="panel" *ngIf="displayCountries == true">
                            <a *ngFor="let country of countries" (click)="selectedCountry(country.countryID, country.name, country.domain)">{{country.name}}</a>
                        </div>
                    </div>


                    <div class="row clearfix" style="margin-top: 7rem!important;">


                        <h5 style="text-align: left;">List of Added Countries</h5>

                        <hr>

                        <div class="row" *ngIf="selectedCountryOptions.length > 0">

                            <div class="col-12 col-md-4 col-lg-4 mb-3 p-2" *ngFor="let selectOption of selectedCountryOptions; let i = index;">
                                <div class="selectedCountry p-2">{{selectOption.countryName}} <i class="fa fa-trash-o delete-icon" aria-hidden="true" (click)="removeCountry(i)"></i></div>
                            </div>

                        </div>

                        <div class="greyText" *ngIf="selectedCountryOptions.length === 0">
                            No country selected.
                        </div>

                    </div>

                </div>

                


                <div class="text-center mt-5" *ngIf="buttonHide === false">

                    <input type="submit" value="Previous" class="nextBtn mx-3 mt-5" (click)="prevStep()"/>

                    <input type="submit" value="Next" class="createBtn mx-3 mt-5" [disabled]="selectedCountryOptions.length === 0 && this.hideMultiSelect === false" (click)="stepTwo()">

                </div>



            </div> 



            <div class="step3 mt-5" *ngIf="thirdSection">
                <h5 class="text-left">Choose your account type:</h5>

                <p>Accounts have :</p>

                <div class="package-details">
                    <ul>
                        <li>24/7 Email and phone support</li>
                        <li>Free account</li>
                        <li>No setup costs</li>
                        <li>Analytics & reporting</li>
                    </ul>
                </div>

                


                <div class="row">

                    <div class="col-12 col-md-4 col-lg-4 p-2" *ngIf="billingProfile === 2 || billingProfile === 1">
                        <div class="package-info p-4 mt-5">
                            <h5>Developers' Central</h5>

                            <p>Integrate my system with a Clickatell API to automate SMS sending.</p> <br/><br/>

                            <span>DEVELOPER ASSISTANCE REQUIRED</span>

                            <div class="text-center mt-4">
                                <input type="submit" value="Create Central Account" class="createBtn" [disabled]="responseData === 'Success: Please check your emails for confirmation.'" (click)="registerUser(billingProfile)"/>
                            </div>

                            <div class="text-center mt-4" *ngIf="toggleResponseMessage === true && (newBillingProfile === 1 || newBillingProfile === 2)">
                                <div class="alert" [ngClass]="responseData === 'Success: Please check your emails for confirmation.' ? 'alert-success' : 'alert-danger'" role="alert" id="alert" style="z-index: -1; font-weight: 500;font-size: 0.8rem;">{{responseData}}</div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 col-md-4 col-lg-4 p-2" *ngIf="billingProfile === 9410">
                        <div class="package-info p-4 mt-5">
                            <h5>Developers' Central</h5>

                            <p style="font-weight: 500; text-align: center !important;">Americas 2 Way SMS</p>

                            <p>US number and bundled messages for sending and receiving in the Americas.</p>

                            <span>DEVELOPER ASSISTANCE REQUIRED</span>

                            <div class="text-center mt-4">
                                <input type="submit" value="Create Central Account" class="createBtn" [disabled]="responseData === 'Success: Please check your emails for confirmation.'" (click)="registerUser(9)"/>
                            </div>

                            <div class="text-center mt-4" *ngIf="toggleResponseMessage === true && this.newBillingProfile === 9">
                                <div class="alert" [ngClass]="responseData === 'Success: Please check your emails for confirmation.' ? 'alert-success' : 'alert-danger'" role="alert" id="alert" style="z-index: -1; font-weight: 500;font-size: 0.8rem;">{{responseData}}</div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12 col-md-4 col-lg-4 p-2" *ngIf="billingProfile === 9410">
                        <div class="package-info p-4 mt-5">
                            <h5>Developers' Central</h5>

                            <p style="font-weight: 500; text-align: center !important;">US Short Code</p>

                            <p>Send and receive high volumes of messages. Easy-to-remember number.</p>

                            <span>DEVELOPER ASSISTANCE REQUIRED</span>

                            <div class="text-center mt-4">
                                <input type="submit" value="Create Central Account" class="createBtn" [disabled]="responseData === 'Success: Please check your emails for confirmation.'" (click)="registerUser(4)"/>
                            </div>

                            <div class="text-center mt-4" *ngIf="toggleResponseMessage === true && this.newBillingProfile === 4">
                                <div class="alert" [ngClass]="responseData === 'Success: Please check your emails for confirmation.' ? 'alert-success' : 'alert-danger'" role="alert" id="alert" style="z-index: -1; font-weight: 500;font-size: 0.8rem;">{{responseData}}</div>
                            </div>
                        </div>
                    </div>

                    <div class="p-2 col-12 col-md-4 col-lg-4 p-2" *ngIf="billingProfile === 9410">
                        <div class="package-info p-4 mt-5">
                            <h5>Developers' Central</h5>

                            <p style="font-weight: 500; text-align: center !important;">Pins & Alerts</p>

                            <p>Send one-time PINs or time critical alerts.</p><br/>

                            <span>DEVELOPER ASSISTANCE REQUIRED</span>

                            <div class="text-center mt-4">
                                <input type="submit" value="Create Central Account" class="createBtn" [disabled]="responseData === 'Success: Please check your emails for confirmation.'" (click)="registerUser(10)"/>
                            </div>

                            <div class="text-center mt-4" *ngIf="toggleResponseMessage === true && this.newBillingProfile === 10">
                                <div class="alert" [ngClass]="responseData === 'Success: Please check your emails for confirmation.' ? 'alert-success' : 'alert-danger'" role="alert" id="alert" style="z-index: -1; font-weight: 500;font-size: 0.8rem;">{{responseData}}</div>
                            </div>
                        </div>
                    </div>
                </div>

                    <div class="text-center mt-5">
                        <input type="submit" value="Previous" class="nextBtn" (click)="stepThree()"  [disabled]="responseData === 'Success'"/>
                    </div>
                  

            </div> 




        </form>


    </div>

    <div class="signup-container pb-5" *ngIf="responseData === 'Success: Please check your emails for confirmation.'">

        <h2 class="text-center">Thank you.</h2>
        <p>Your password has been sent to the email address you submitted. Please contact <a href="mailto:support@clickatell.com" style="text-decoration: none;">support@clickatell.com</a> should you experience any further problems.</p>

    </div>

<app-footer></app-footer>