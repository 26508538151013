<app-header></app-header>

    <div class="login-container pb-5">

        <h5>Sign in</h5>

        <p>Welcome back! Please enter your
            username and password below to log in.</p>

        <form [formGroup] = "loginForm" class="text-left pt-0" autocomplete="off">

            <div class="form-group my-3">
                <label for="Username">Username</label>
                <input type="text" formControlName="username" name="username" class="form-control" id="Username" [class.is-invalid]="loginForm.get('username')!.invalid && loginForm.get('username')!.touched" (focusout)="checkDuplicateAccount($event)">
            </div>

            <div *ngIf="dublicateUserCheck!.Error === 'Duplicate username - ClientID required.'" class="loginOptions pt-2">
                <small>Can you give us more info? Which account type did you sign up for?</small>

                <div class="form-check mt-4">

                    <input class="form-check-input" formControlName="accountType" type="radio" name="accountType" id="developerCentral" value="developer-central" (change)="eventCheck($event)">
                    <label class="form-check-label" for="developerCentral">
                    Developers' Central
                    </label>
                </div>

                <div class="form-check mb-4">
                    <input class="form-check-input" formControlName="accountType" type="radio" name="accountType" id="communicator2" value="communicator2" (change)="eventCheck($event)">
                    <label class="form-check-label" for="communicator2">
                    Communicator 2
                    </label>
                </div>

            </div>



            <div class="form-group my-3" *ngIf="dublicateUserCheck!.Error === 'Duplicate username - ClientID required.' && clientIDRequired === true">
                <label for="ClientID">Client ID</label>
                <input type="text" formControlName="clientid" name="clientid" class="form-control" id="clientid" [class.is-invalid]="loginForm.get('clientid')!.invalid && loginForm.get('clientid')!.touched">
            </div>

            <div class="form-group my-3">
                <label for="userPassword">Password</label>
                <input type="password" formControlName="password" name="password" class="form-control" id="userPassword" [class.is-invalid]="loginForm.get('password')!.invalid && loginForm.get('password')!.touched">
            </div>

            <re-captcha *ngIf="responseMessage!.Status === 'Failed' && responseMessage!.Error === 'CAPTCHA'" class="mb-2"(resolved)="resolved($event)" (error)="onError($event)" errorMode="handled" siteKey="6LfhM9oaAAAAAJ2oDzy5lkuSAFE7qCLdDQgEs65k" #captchaControl ngDefaultControl></re-captcha>

            <div class="alert alert-warning mt-4" *ngIf="loading" role="alert" id="alert" style="z-index: -1; text-align: center;">Please wait... <i class="fa fa-spinner fa-pulse fa-3x fa-fw" style="font-size: 20px"></i>
                <span class="sr-only">Loading...</span>
            </div>
            <input type="hidden" id="csrfToken" formControlName="preAuthXsrfToken"/>
            <!--Login  using javascript-->
            <div class="alert alert-danger mt-4" *ngIf="error_message" role="alert" id="alert" style="z-index: -1;">{{error_message_response}}</div>

            <div class="text-center">
                <input type="submit" value="Login" class="loginBtn" (click)="signIn()" [disabled]="!loginForm.get('username')!.valid || !loginForm.get('password')!.valid"/>
            </div>
        </form>

        <div class="row mt-5">

            <div class="col-12 text-center">
                <a class="login-links" [routerLink]="['/forgot-password']">Forgot password?</a>
            </div>

            <!-- <div class="col-12 text-center mt-4" style="opacity: 0.1;">
                <a class="login-links" [routerLink]="['/sign-up']">Click here to Signup</a>
            </div> -->

        </div>

    </div>


<app-footer></app-footer>
