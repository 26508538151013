<div class="bottomSection py-5">
    <div class="container">
        <div class="row">

            <div class="col-6 col-md-3 col-lg-3 p-3">
                <a href="https://www.clickatell.com/products/"><strong>PRODUCTS & PRICING</strong></a>
                <ul>
                    <li><a href="https://www.clickatell.com/products/platform/">Platform</a></li>
                    <li><a href="https://www.clickatell.com/products/touch/">Touch</a></li>
                    <li><a href="https://www.clickatell.com/pricing-and-coverage/message-pricing/">SMS Pricing Estimator</a></li>
                </ul>
            </div>

            <div class="col-6 col-md-3 col-lg-3 p-3">
                <a href="https://www.clickatell.com/help-center/"><strong>RESOURCES & HELP</strong></a>

                <ul>
                    <li><a href="https://www.clickatell.com/case-studies/">Case studies</a></li>
                    <li><a href="https://www.clickatell.com/articles/">Articles</a></li>
                    <li><a href="https://www.clickatell.com/white-papers/">White papers</a></li>
                    <li><a href="https://www.clickatell.com/faqs/">FAQs</a></li>
                </ul>
            </div>

            <div class="col-6 col-md-3 col-lg-3 p-3">
                <a href="https://www.clickatell.com/developers/"><strong>DEVELOPER TOOLS</strong></a>

                <ul>
                    <li><a href="https://www.clickatell.com/developers/platform/">Platform</a></li>
                    <li><a href="https://www.clickatell.com/developers/archive/">Developer archive</a></li>
                </ul>
            </div>

            <div class="col-6 col-md-3 col-lg-3 p-3">
                <a href="https://www.clickatell.com/the-company/"><strong>THE COMPANY</strong></a>

                <ul>
                    <li><a href="https://www.clickatell.com/who-are-we/">Who are we?</a></li>
                    <li><a href="https://www.clickatell.com/jobs/">Jobs at Clickatell</a></li>
                    <li><a href="https://www.clickatell.com/people/">Our people</a></li>
                    <li><a href="https://www.clickatell.com/press-center/">Press center</a></li>
                </ul>
            </div>

        </div>
    </div>
</div>

<div class="footer py-4">
    <div class="container">
        <img src="/assets/clickatell-footer-logo.png">
        <span>© Copyright 2017 Clickatell. All rights reserved.</span>
        <span><a href="https://www.clickatell.com/legal/master-terms/">Terms & Conditions</a></span>
        <span><a href="https://www.clickatell.com/legal/general-terms-notices/cookie-notice/">Cookie Policy</a></span>
    </div>
</div>