import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http'
import { Observable } from 'rxjs';
declare var serialize:any;

@Injectable({providedIn: 'root'})
export class AuthenticateService {
  private token = environment.token;
  private preAuthFilter: any;
  private headers: any;

  constructor(private http: HttpClient) {
    let reqData: any = new Array();
    reqData['username'] = btoa('preauth');
    this.preAuthFilter = {
      username: btoa('preauth'),
      password: btoa(''),
      preAuth: true,
      paramLogin: false,
      duplicateCheck: false,
    }
    this.headers = new HttpHeaders().set(
      'Content-Type',
      'text/plain; charset=utf-8'
    );
  }

  public checkDuplicateUserAccounts(content: any, type: any, filter: any, contentAPI: any): Observable<any>{
    return this.http.post(contentAPI+'?token='+this.token+'&content=loginNew&type='+type+'&filter='+filter,
      '',
      {
        headers: this.headers,
        responseType: 'text'
      });
  }

  public loginUser(content: any, type: any, filter: any, contentAPI: any): Observable<any>{
    return this.http.post(contentAPI+'?token='+this.token+'&content=loginNew&type='+type+'&filter='+filter,
      '',
      {
        headers: this.headers,
        responseType: 'text',
        observe: 'response'
      });
  }

  public forgotPassword(content: any, type: any, filter: any, contentAPI: any): Observable<any>{
    return this.http.post(contentAPI+'?token='+this.token+'&content='+content+'&type='+type+'&filter='+filter,
      '',
      {
        headers: this.headers,
        responseType: 'text'
      });
  }

  public signupUser(content: any, type: any, filter: any, contentAPI: any): Observable<any>{
    return this.http.post(contentAPI+'?token='+this.token+'&content='+content+'&type='+type+'&filter='+filter,
      '',
      {
        headers: this.headers,
        responseType: 'text'
    });
  }

  public validateUsername(content: any, type: any, filter: any, contentAPI: any): Observable<any>{
    return this.http.post(
      contentAPI+'?token='+this.token+'&content='+content+'&type='+type+'&filter='+filter,
      '', {
        headers: this.headers,
        responseType: 'text'
      });
  }

  public preAuth(contentAPI: string, preAuthXsrfToken = null): Observable<any>{
    if (preAuthXsrfToken != null && preAuthXsrfToken != '') {
      this.preAuthFilter.preAuthXsrfToken = preAuthXsrfToken;
    }
    let preAuthFilter = btoa(serialize(this.preAuthFilter))
    return this.http.post(
      contentAPI+'?token='+this.token+'&content=loginNew&type=serialize'+'&filter='+preAuthFilter,
      '', {
        headers: this.headers,
        responseType: 'text',
        observe: 'response'
      });
  }
}
